/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import { FC } from 'react'
import Box from '@mui/material/Box'
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getProviderCredentialsRequest } from '../../../../api'
import { KTSVG } from '../../../../_metronic/helpers'
import { useProviderCredential, useProviderMedia } from '../hooks/useProviderQuery'
import { makeDate } from '../../../../util'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: '#1e1e2d',
  border: '2px solid #000',
  boxShadow: 24,
}

type Props = {}

const ProvidersCredentials: FC<Props> = () => {
  const { Id } = useParams()

  const { isLoading, data } = useProviderCredential(Id as any);
  const [item, setItem]: any = React.useState()

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <div style={{ minHeight: '50vh' }} className={`card mb-5 mb-xl-10 py-8 ${data?.length === 0 && "d-flex justify-content-center"}`}>
        {isLoading ? (
          <div className='d-flex justify-content-center my-5'>
            <div className='spinner-grow text-dark' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='row mx-3 g-4'>
            {data?.length === 0 ? (
              <div className='text-center mt-4 mb-5'>
                <h3 className='text-muted'>No matching records found :(</h3>
              </div>
            ) : (data?.map((u: any, idx: any) => (
              <div key={idx} onClick={handleOpen} className='col-4 pointer'>
                <div onClick={() => setItem(u)} className="border border-2 rounded">
                  <img
                    style={{ objectFit: 'cover' }}
                    height={'250px'}
                    src={u?.thumbnailUrl}
                    className='card-img-top rounded-3'
                    alt='...'
                  />
                  <div className='card-body py-4'>
                    <div className='row'>
                      <div className='col-12 px-0'>
                        <h5 key={idx} className='card-title badge badge-secondary fs-5 me-2 text-wrap'>
                          {u?.title}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )))
            }
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} className='px-8 pb-12 pt-0'>
          <div className='text-end mb-2'>
            {/* begin::Modal title */}
            {/* <h2 className='fw-bolder text-white'>Media</h2> */}
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
              onClick={handleClose}
              className='btn btn-icon btn-sm btn-active-icon-primary'
              style={{ cursor: 'pointer' }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
            {/* end::Close */}
          </div>
          <div className='bg-white mx-4'>
            <div className="row g-0">
              <div className="col-md-7 bg-secondary roudned-5">
                <img
                  style={{ objectFit: 'contain' }}
                  height={'500px'}
                  src={item?.imageUrl}
                  className='card-img-top rounded-3'
                  alt='...'
                />
              </div>
              <div className="col-md-5 py-3 ps-5">
                <div className="row mt-1">
                  <div className="col d-flex flex-column">
                    <div className='fw-bold text-muted mb-2'>Name:</div>
                    <p className='fs-6 fw-bold'>{item?.name}</p>
                  </div>
                  <div className="col d-flex flex-column">
                    <div className='fw-bold text-muted mb-2'>Title:</div>
                    <p className='fs-6 fw-bold'>{item?.title}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="d-flex flex-column col">
                    <div className='fw-bold text-muted mb-2'>Issue Date:</div>
                    {item?.dateTime ? <p className='fs-6 fw-bold'>{makeDate(item?.issueDate)}</p> : <p className='fs-6 fw-bold'>N/a</p>}
                  </div>
                  <div className="d-flex flex-column col">
                    <div className='fw-bold text-muted mb-2'>Expiry Date:</div>
                    {item?.dateTime ? <p className='fs-6 fw-bold'>{makeDate(item?.expiryDate)}</p> : <p className='fs-6 fw-bold'>N/a</p>}
                  </div>
                </div>
                <div className="d-flex flex-column mt-4">
                  <div className='fw-bold text-muted mb-2'>Link:</div>
                  <p className='fs-6 fw-bold'>{item?.link ? item?.link : "N/a"}</p>
                </div>
                <div className="d-flex flex-column mt-4">
                  <div className='fw-bold text-muted mb-2'>Type:</div>
                  <p className='fs-6 fw-bold'>{item?.type ? item?.type : "N/a"}</p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default ProvidersCredentials
