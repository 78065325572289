import InfoWrapper from '../../../modules/common/SectionInfo'
import {updateNotificationState} from '../../../reducers/configurationReducer'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {AppDispatch} from '../../../store'
import NotificationModal from './modal/NotificationModal'
import {KTSVG} from '../../../../_metronic/helpers'
import {extractSMSValues} from '../../../../util/helper'

const SMSConfig = ({state}: any) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [activeTrigger, setActiveTrigger] = useState('client')
  const dispatch = useDispatch<AppDispatch>()

  // console.log('triggers', state?.value?.['member-sms'])
  const providerSms = extractSMSValues(state?.value?.['provider-sms'])
  const clientSms = extractSMSValues(state?.value?.['member-sms'])
  const [notification, setNotification] = useState<any>()

  const handleChange = (
    type: string,
    index: any,
    key: any,
    name: string,
    value: string | number
  ) => {
    // console.log('value', value)
    // console.log('name', name)
    // console.log('key', key)
    // console.log('idx', index)
    // console.log('TYPE', type)

    if (type === 'member-sms') {
      const updatedClientTriggers = {...state?.value?.['member-sms']}
      // console.log('dta', updatedClientTriggers)

      let triggers = updatedClientTriggers
      triggers[key] = {
        ...triggers[key],
        [name]: value,
      }

      setNotification({
        ...notification,
        message: value,
      })

      // Update the state or perform any other necessary actions

      dispatch(updateNotificationState({data: triggers, name: 'member-sms'}))

      // Update the state or perform any other necessary actions
    } else if (type === 'provider-sms') {
      const updatedProviderTriggers = {...state?.value?.['provider-sms']}

      let triggers = updatedProviderTriggers
      triggers[key] = {
        ...triggers[key],
        [name]: value,
      }

      // (prevData: any) => ({
      //   ...prevData,
      //   value: {
      //     ...prevData.value,
      //     'provider-sms': triggers,
      //   },
      // })

      // Update the state or perform any other necessary actions
      dispatch(updateNotificationState({data: triggers, name: 'provider-sms'}))

      // console.log('triggers', triggers)
      // console.log('data', data?.value)
    }
  }

  return (
    <div className='card' id='kt_profile_details_view'>
      <div className='card-body px-9 py-2'>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap w-100'>
            <li className='nav-item w-50 d-flex justify-content-center'>
              <div
                className={`nav-link text-active-primary me-6 pointer w-100 ${
                  activeTrigger === 'client' ? 'active' : ''
                }`}
                onClick={() => setActiveTrigger('client')}
              >
                Client SMS
              </div>
            </li>
            <li className='nav-item w-50 d-flex'>
              <div
                className={`nav-link text-active-primary me-6 pointer w-100 ${
                  activeTrigger === 'provider' ? 'active' : ''
                }`}
                onClick={() => setActiveTrigger('provider')}
              >
                Provider SMS
              </div>
            </li>
          </ul>
        </div>
        <div className='mt-4'>
          {activeTrigger === 'client' ? (
            <InfoWrapper title=''>
              <div className='row'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-lg-4 col-form-label fw-bold fs-6'>Events</div>
                    <div className='col-lg-2 col-form-label fw-bold fs-6'>Status</div>
                    <div className='col-lg-6 col-form-label fw-bold fs-6'>Sample Message</div>
                  </div>
                  {clientSms?.map((data: any, idx: number) => (
                    <div
                      className={`row py-2 ${idx + 1 !== clientSms?.length && 'border-bottom'}`}
                      key={data?.key}
                    >
                      <div
                        className='col-lg-4'
                        style={{
                          // whiteSpace: 'nowrap',
                          wordBreak: 'break-word',
                          // overflow: 'hidden',
                          // textOverflow: 'ellipsis',
                        }}
                      >
                        <label className='col-form-label fw-bold fs-6'>
                          <span>{data?.key}</span>
                        </label>
                      </div>
                      <div className='col-lg-2'>
                        <div className='d-flex'>
                          <div className='form-check form-check form-switch fv-row'>
                            <input
                              className={`form-check-input w-45px h-30px`}
                              type='checkbox'
                              name='is_active'
                              onChange={(e) =>
                                handleChange(
                                  'member-sms',
                                  idx,
                                  data?.key,
                                  e.target.name,
                                  e.target.checked ? 1 : 0
                                )
                              }
                              checked={data?.is_active === 1 ? true : false}
                              id='payabli'
                            />
                            <label className='form-check-label'></label>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6 pb-2'>
                        <div className='d-flex'>
                          <textarea
                            className='form-control'
                            name='message'
                            // onChange={(e) =>
                            //   handleChange(
                            //     'client-triggers',
                            //     idx,
                            //     data?.key,
                            //     e.target.name,
                            //     e.target.value
                            //   )
                            // }
                            cols={3}
                            style={{
                              resize: 'none',
                            }}
                            disabled
                            value={data?.message}
                          />
                        </div>
                        <div
                          className='text-end mt-1'
                          onClick={() => {
                            handleOpen()
                            setNotification({
                              ...data,
                              type: 'member-sms',
                              idx: idx,
                            })
                          }}
                        >
                          <div className='p-2'>
                            <KTSVG
                              path={'/media/icons/duotune/general/gen055.svg'}
                              className='svg-icon-2 border p-2 pointer bg-light'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </InfoWrapper>
          ) : (
            <InfoWrapper title=''>
              <div className='row'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-lg-4 col-form-label fw-bold fs-6'>Events</div>
                    <div className='col-lg-2 col-form-label fw-bold fs-6'>Status</div>
                    <div className='col-lg-6 col-form-label fw-bold fs-6'>Sample Message</div>
                  </div>
                  {providerSms?.map((data: any, idx: number) => (
                    <div
                      className={`row py-2 ${idx + 1 !== providerSms?.length && 'border-bottom'}`}
                      key={data?.key}
                    >
                      <div
                        className='col-lg-4'
                        style={{
                          // whiteSpace: 'nowrap',
                          // overflow: 'hidden',
                          // textOverflow: 'ellipsis',
                          wordBreak: 'break-word',
                        }}
                      >
                        <label className='col-form-label fw-bold fs-6'>
                          <span>{data?.key}</span>
                        </label>
                      </div>
                      <div className='col-lg-2'>
                        <div className='d-flex'>
                          <div className='form-check form-check form-switch fv-row'>
                            <input
                              className={`form-check-input w-45px h-30px`}
                              type='checkbox'
                              name='is_active'
                              checked={data?.is_active === 1 ? true : false}
                              onChange={(e) =>
                                handleChange(
                                  'provider-sms',
                                  idx,
                                  data?.key,
                                  e.target.name,
                                  e.target.checked ? 1 : 0
                                )
                              }
                              id='payabli'
                            />
                            <label className='form-check-label'></label>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6 pb-2'>
                        <div className='d-flex'>
                          <textarea
                            className='form-control'
                            name='message'
                            // onChange={(e) =>
                            //   handleChange(
                            //     'provider-triggers',
                            //     idx,
                            //     data?.key,
                            //     e.target.name,
                            //     e.target.value
                            //   )
                            // }
                            disabled
                            value={data?.message}
                            cols={3}
                            style={{
                              resize: 'none',
                            }}
                          />
                        </div>
                        <div
                          className='text-end mt-1'
                          onClick={() => {
                            handleOpen()
                            setNotification({
                              ...data,
                              type: 'provider-sms',
                              idx: idx,
                            })
                          }}
                        >
                          <div className='p-2'>
                            <KTSVG
                              path={'/media/icons/duotune/general/gen055.svg'}
                              className='svg-icon-2 border p-2 pointer bg-light'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </InfoWrapper>
          )}
          <div className='d-flex justify-content-end py-6'>
            <NotificationModal
              open={open}
              handleClose={handleClose}
              handleChange={handleChange}
              data={notification}
              setData={setNotification}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SMSConfig
