import QRCode from 'react-qr-code'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import Info from '../../../../../modules/common/Info'
import SquareState, {ArrowDirections} from '../../../../../modules/common/SquareState'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import axios from 'axios'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

interface Props {
  data?: any
}
const Overview = ({data}: Props) => {
  const {Id} = useParams()
  const [link, setLink] = useState('')
  const downloadQRCodeAsPDF = () => {
    const qrCodeElement: any = document.querySelector('#qrcode') // Replace 'qrcode' with the actual ID of your QRCode component

    html2canvas(qrCodeElement).then((canvas) => {
      const image = canvas.toDataURL('image/png')
      const pdf = new jsPDF()
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imageWidth = canvas.width * 1.5
      const imageHeight = canvas.height * 1.5
      const offsetX = (pdfWidth - imageWidth) / 2 // Calculate horizontal offset for centering
      const offsetY = (pdfHeight - imageHeight) / 4 // Calculate vertical offset for centering

      pdf.addImage(image, 'PNG', offsetX, offsetY, imageWidth, imageHeight)

      pdf.save('qrcode.pdf')
    })
  }

  // create a deep link to the member apk app for download
  const createDeepLink = async () => {
    const link = await axios({
      method: 'post',
      url: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDlk11w31BH-O8oT8Loocx4YQQrbzOrgQ8',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        dynamicLinkInfo: {
          domainUriPrefix: 'https://kazzahpro.page.link',
          link: `https://www.kazzah.com/?memberId=${Id}`,
          iosInfo: {
            iosAppStoreId: process.env.REACT_APP_PROVIDER_APP_STORE_ID,
            iosBundleId: process.env.REACT_APP_PROVIDER_PACKAGE_ID,
          },
          androidInfo: {
            androidPackageName: process.env.REACT_APP_PROVIDER_PACKAGE_ID,
          },
        },
        suffix: {
          option: 'SHORT',
        },
      },
    })
    setLink(link.data.shortLink)

    return link.data.shortLink
  }

  useEffect(() => {
    createDeepLink()
  }, [])

  return (
    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
      <div className='me-7 mb-4'>
        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border'>
          <img
            src={
              !data?.profileImage ? toAbsoluteUrl('/media/avatars/blank.png') : data?.profileImage
            }
            alt='provider avatar'
            style={{objectFit: 'contain'}}
          />
          <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
        </div>
      </div>

      <div className='flex-grow-1'>
        {/* top section */}
        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
          <div className='d-flex flex-column'>
            {/* profile image */}
            <div className='d-flex align-items-center mb-2'>
              <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                {false ? 'Loading...' : data?.username}
              </span>
              <span>
                <KTSVG
                  path='/media/icons/duotune/general/gen026.svg'
                  className='svg-icon-1 svg-icon-primary'
                />
              </span>
            </div>

            {/* basic info */}
            <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
              <Info
                iconPath='/media/icons/duotune/general/gen018.svg'
                text={`${data?.state}, ${data?.city}`}
                isLoading={false}
              />
              <Info
                iconPath='/media/icons/duotune/communication/com011.svg'
                text={data?.email}
                isLoading={false}
              />
            </div>
          </div>

          <div className='d-flex my-4 flex-column align-items-center justify-content-center gap-2'>
            <button className='btn btn-sm btn-primary' onClick={downloadQRCodeAsPDF}>
              Download QR
            </button>
            <div
              style={{height: 'auto', margin: '0 auto', maxWidth: 64, width: '100%'}}
              id='qrcode'
            >
              <QRCode
                size={512}
                style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                value={link}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        </div>

        {/* bottom section */}
        <div className='d-flex flex-wrap flex-stack'>
          <div className='d-flex flex-column flex-grow-1 pe-8'>
            <div className='d-flex flex-wrap'>
              <SquareState title='Amount Spent' value={`13450$`} />
              <SquareState title='Appointments' value={data?.appointments?.length} />
              {/* <SquareState title='Client Served' value={`490`} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview
