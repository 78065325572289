/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { BsArrowRightCircleFill } from 'react-icons/bs';
import { HiLocationMarker } from 'react-icons/hi';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { makeDate } from '../../../../../../util';

type Props = {
    data: any
}

type vacation = {
    location?: string
    startDateTime?: string
    endDateTime?: string
}

const Vacation: FC<Props> = ({ data }: Props) => {
    return (
        <div style={{ minHeight: '50vh' }} className={`card ${data?.length === 0 && "d-flex justify-content-center"}`}>
            {data.length !== 0 ?
                <div className='card-body d-flex flex-end flex-column px-2 py-1 rounded'>
                    {/* begin::Modal title */}
                    <div className="container-fluid text-center mt-4">
                        <div className="row">
                            {data?.map(({ location, startDateTime, endDateTime }: vacation, idx: number) => (
                                <div key={idx} className='d-flex flex-column col-lg-6'>
                                    <div className="bg-light rounded my-3 pb-5">
                                        <h5 className='pointer text-gray-800 text-hover-primary fw-bolder mb-n4 py-5'>Vacation {idx + 1}</h5>
                                        <div className='row mx-2 align-items-center gy-3'>
                                            <div className="col-12 text-start">
                                                <span className='fw-bold d-block'>
                                                    <HiLocationMarker size={'1.5rem'} className="me-2" />
                                                    {location}
                                                </span>
                                            </div>
                                            <hr />
                                            <div className="col-4 text-start my-auto mb-3">
                                                <p className='mb-0 fw-bold'>
                                                    Day Begin
                                                </p>
                                            </div>
                                            <div className='col-8 text-end d-flex mb-3'>
                                                <p className='fw-bold d-block m-auto me-3'>{startDateTime ? makeDate(startDateTime) : 'N/a'}</p>
                                                <BsArrowRightCircleFill size={'1.5rem'} />
                                            </div>
                                            <div className="col-4 text-start my-auto mb-3">
                                                <p className='mb-0 fw-bold'>
                                                    Day End
                                                </p>
                                            </div>
                                            <div className='col-8 text-end d-flex mb-3'>
                                                <p className='fw-bold d-block m-auto me-3'>{endDateTime ? makeDate(endDateTime) : 'N/a'}</p>
                                                <BsArrowLeftCircleFill size={'1.5rem'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                :
                <div className='text-center mt-4 mb-5'>
                    <h3 className='text-muted'>No matching records found :(</h3>
                </div>
            }
        </div>
    )
}

export { Vacation }
