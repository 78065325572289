import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getAllPaymentSummaryRequest} from '../../../api/endpoints/paymentSummary'
import {attachedPaginatedPayload} from '../../../util'

interface paymentSummaryState {
  paymentSummary: Array<any>
  totalAmount: number
  pagination: any
  status: string
  error: string | null
}

const initialState: paymentSummaryState = {
  paymentSummary: [],
  totalAmount: 0,
  pagination: {},
  status: 'idle',
  error: null,
}

export const getAllPaymentSummaryAction = createAsyncThunk(
  'get/transactions',
  async (params: any) => {
    try {
      const res: any = await getAllPaymentSummaryRequest(params)
      const {data, success, message} = res.data
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {
      console.log('get transactions error', error)
      return error
    }
  }
)

const paymentSummarySlice = createSlice({
  name: 'paymentSummary',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllPaymentSummaryAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getAllPaymentSummaryAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      state.paymentSummary = action.payload.data.appointments
      state.totalAmount = action.payload.data.totalAmount
      state.pagination = attachedPaginatedPayload(action.payload.data)
    })
    builder.addCase(getAllPaymentSummaryAction.rejected, (state, action) => {
      state.status = 'failed'
    })
  },
})

export const paymentSummaryStatusSelector = (state: any) => state.paymentSummary.status
export const paymentSummarySelector = (state: any) => state.paymentSummary.paymentSummary
export const paymentSummaryPaginationSelector = (state: any) => state.paymentSummary.pagination
export const paymentSummaryTotalAmountSelector = (state: any) => state.paymentSummary.totalAmount

export default paymentSummarySlice.reducer
