import React, { useState } from 'react'
import SecondLvlService from './components/SecondLvlService'
import ProvidersServiceList from './components/ServiceList'

const ProviderServices = () => {
    const [currentService, setCurrentService] = useState({
        lvl: 2,
        serviceId: null
    })
    return (
        <>
            {currentService.lvl === 3 ?
                <ProvidersServiceList currentService={currentService} />
                :
                <SecondLvlService setLvl={setCurrentService} />
            }
        </>
    )
}

export default ProviderServices