import React, {useState} from 'react'
import {
  ProviderAppointment,
  ProviderCredential,
  ProviderMedia,
  ProviderMember,
  ProviderPayment,
  ProviderProfile,
  ProviderSchedule,
  ProviderService,
  ProviderTopHeader,
} from '.'

const NewProfile = () => {
  const [tab, setTab] = useState<string>('profile')
  return (
    <div>
      <ProviderTopHeader tab={tab} setTab={setTab} />
      {tab === 'profile' ? (
        <ProviderProfile />
      ) : tab === 'members' ? (
        <ProviderMember />
      ) : tab === 'services' ? (
        <ProviderService />
      ) : tab === 'appointment' ? (
        <ProviderAppointment />
      ) : tab === 'schedule' ? (
        <ProviderSchedule />
      ) : tab === 'media' ? (
        <ProviderMedia />
      ) : tab === 'credentials' ? (
        <ProviderCredential />
      ) : tab === 'payments' ? (
        <ProviderPayment />
      ) : null}
    </div>
  )
}

export default NewProfile
