import client from '../client/client'

const getAllTransactionsRequest = async (params: any) => {
  try {
    return await client.get('api/v1/admin/transaction-type', {
      params: params,
    })
  } catch (error) {
    return error
  }
}

const getSingleTransactionsRequest = async (id: string | number) => {
  try {
    return await client.get(`api/v1/admin/transaction-type/${id}`)
  } catch (error) {
    return error
  }
}

const addTransactionsRequest = async (data: any) => {
  try {
    return await client.post('api/v1/admin/transaction-type', data)
  } catch (error) {
    return error
  }
}

const updateTransactionsRequest = async (data: any, id: string | number) => {
  try {
    return await client.put(`api/v1/admin/transaction-type/${id}`, data)
  } catch (error) {
    return error
  }
}

const deleteTransactionsRequest = async (id: string | number) => {
  try {
    return await client.delete(`api/v1/admin/transaction-type/${id}`)
  } catch (error) {
    return error
  }
}

export {
  getAllTransactionsRequest,
  getSingleTransactionsRequest,
  addTransactionsRequest,
  updateTransactionsRequest,
  deleteTransactionsRequest,
}
