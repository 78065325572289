import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Box, Modal} from '@mui/material'

const NotificationModal = ({open, handleClose, handleChange, data, setData}: any) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: '#1e1e2d',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  // console.log('data', data)

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose()
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box className='' sx={style}>
        <div className='modal-header'>
          {/* begin::Modal title */}
          <h2 className='fw-bolder text-light'>Notification message</h2>
          {/* end::Modal title */}

          {/* begin::Close */}
          <div
            onClick={() => {
              handleClose()
            }}
            className='btn btn-icon btn-sm btn-active-icon-primary'
            style={{cursor: 'pointer'}}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
          {/* end::Close */}
        </div>
        <form className='text-center m-5'>
          <div className='row'>
            <div className='col-12'>
              <div className='d-flex justify-content-between flex-column'>
                <textarea
                  className='form-control'
                  name='message'
                  onChange={(e) =>
                    setData({
                      ...data,
                      message: e.target.value,
                    })
                  }
                  value={data?.message}
                  rows={5}
                  cols={12}
                  style={{
                    resize: 'none',
                  }}
                />
                {data?.available_variables && (
                  <div className='text-light text-start'>
                    <span className='fw-bold'>Available variables</span>
                    <div className='d-flex gap-2'>
                      <div className=''>{data?.available_variables}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <button
              onClick={() => {
                handleClose()
                handleChange(data?.type, data?.idx, data?.key, 'message', data?.message)
              }}
              type='button'
              className='btn btn-primary mt-4'
              aria-label='submit data'
            >
              Done
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  )
}

export default NotificationModal
