import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {deleteExplore, getExplores} from '../../../api/endpoints/explore'
import {attachedPaginatedPayload} from '../../../util'
import {toast} from 'react-toastify'

interface exploreState {
  medias: Array<any>
  pagination: any
  filter: {
    query: string
    userType: string
  }
  deleteStatus: string
  status: string
  error: string | null
}

const initialState: exploreState = {
  medias: [],
  pagination: {},
  filter: {
    query: '',
    userType: '',
  },
  status: 'idle',
  deleteStatus: 'idle',
  error: null,
}

export const getAllMediaAction = createAsyncThunk('get/all-media', async (params: any) => {
  try {
    const res: any = await getExplores(params)
    const {data, success} = res.data
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

export const deleteMediaAction = createAsyncThunk('delete/media', async (params: any) => {
  try {
    const res: any = await deleteExplore(params.id)
    const {data, success} = res.data
    if (success) {
      toast.success('Media successfully deleted', {
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      params.handleClose()
      return {
        id: params.id,
        data,
        success,
        code: 200,
      }
    } else {
      toast.error('Something went wrong', {
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })

      return {
        data,
        success: false,
        code: 400,
      }
    }
  } catch (error) {
    return error
  }
})

const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    addMediaToState: (state, action) => {
      const {data} = action.payload
      const media = data.medias
      const pagination = attachedPaginatedPayload(data)
      state.pagination = pagination
      state.medias = [...state.medias, ...media]
    },
    aggregateFilter: (state: any, {payload}: PayloadAction<any>) => {
      const {key, value} = payload
      state.filter[key] = value
    },
    resetFilters: (state, {payload}: {payload?: any}) => {
      state.filter = initialState.filter
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMediaAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getAllMediaAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      state.medias = action.payload.data?.medias
      state.pagination = attachedPaginatedPayload(action.payload.data)
    })
    builder.addCase(getAllMediaAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(deleteMediaAction.pending, (state, action) => {
      state.deleteStatus = 'loading'
    })
    builder.addCase(deleteMediaAction.fulfilled, (state, action: any) => {
      state.deleteStatus = 'success'
      const filteredMedia = state.medias?.filter((media: any) => media?.id !== action.payload.id)
      state.medias = filteredMedia
    })
    builder.addCase(deleteMediaAction.rejected, (state, action) => {
      state.status = 'failed'
    })
  },
})

export const {addMediaToState, aggregateFilter, resetFilters} = exploreSlice.actions

export const mediasSelector = (state: any) => state.explore.medias
export const mediaPaginationSelector = (state: any) => state.explore.pagination
export const mediaStatusSelector = (state: any) => state.explore.status
export const mediaFilterSelector = (state: any) => state.explore.filter
export const mediaDeleteStatusSelector = (state: any) => state.explore.deleteStatus
export const errorSelector = (state: any) => state.explore.error

export default exploreSlice.reducer
