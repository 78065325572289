import React from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {matchUrl} from '../../../../../../util'

type Props = {
  tab?: string
  setTab?: any
}
const RoutingTabs = ({tab, setTab}: Props) => {
  return (
    <div className='d-flex overflow-auto h-55px'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'profile' ? 'active' : ''
            }`}
            // to={`/provider/${Id}/profile`}
            onClick={() => setTab('profile')}
          >
            Profile
          </div>
        </li>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'members' ? 'active' : ''
            }`}
            onClick={() => setTab('members')}
            // to={`/provider/${Id}/members`}
          >
            Members
          </div>
        </li>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'services' ? 'active' : ''
            }`}
            // to={`/provider/${Id}/services`}
            onClick={() => setTab('services')}
          >
            Services
          </div>
        </li>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'appointment' ? 'active' : ''
            }`}
            // to={`/provider/${Id}/appointment`}
            onClick={() => setTab('appointment')}
          >
            Appointments
          </div>
        </li>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'schedule' ? 'active' : ''
            }`}
            // to={`/provider/${Id}/schedule`}
            onClick={() => setTab('schedule')}
          >
            Schedules
          </div>
        </li>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'media' ? 'active' : ''
            }`}
            // to={`/provider/${Id}/media`}
            onClick={() => setTab('media')}
          >
            Media
          </div>
        </li>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'credentials' ? 'active' : ''
            }`}
            // to={`/provider/${Id}/credentials`}
            onClick={() => setTab('credentials')}
          >
            Credentials
          </div>
        </li>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'payments' ? 'active' : ''
            }`}
            // to={`/provider/${Id}/payments`}
            onClick={() => setTab('payments')}
          >
            Payments
          </div>
        </li>
      </ul>
    </div>
  )
}

export default RoutingTabs
