import React from 'react'
import Chip from './Chip'
import ExploreModal from './ExploreModal'

type cardProps = {
  data: any
  index?: number
  handleOpen?: any
  setItem?: any
}

const MediaCard = ({ data, index, handleOpen, setItem }: cardProps) => {



  return (
    <>
      <div onClick={() => {
        handleOpen()
        setItem(index)
      }} className='col-4 pointer'>
        <div className='border border-2 rounded'>
          <img
            style={{ objectFit: 'cover' }}
            height={'250px'}
            src={data?.thumbnail}
            className='card-img-top rounded-3'
            alt='...'
          />
          <div className='card-body py-4'>
            <div className='row'>
              <div className='col-12 px-0 h-50px overflow-auto'>
                {data.tags?.length > 0 &&
                  data?.tags?.map(({ service }: any, idx: number) => (
                    <h5 key={idx} className='card-title badge badge-secondary fs-5 me-2'>
                      {service?.name}
                    </h5>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MediaCard
