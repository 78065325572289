import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Box, MenuItem, Modal, Typography} from '@mui/material'
import {MdDelete, MdFavorite} from 'react-icons/md'
import {FaDownload} from 'react-icons/fa'
import {saveAs} from 'file-saver'
import {deleteMediaAction, mediaDeleteStatusSelector} from '../../../reducers/exploreReducer'

interface UserProfileFooterTypes {
  item: any
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#1e1e2d',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const UserProfileFooter = ({item}: UserProfileFooterTypes) => {
  const [like, setLike] = React.useState({
    isFav: item?.isFav,
    mediaLikeCounts: item?.mediaLikeCounts,
  })
  const [deleteItem, setDeleteItem] = React.useState('')

  const deleteStatus = useSelector(mediaDeleteStatusSelector)
  const downloadFile: any = () => {
    saveAs(item?.url, `${Date.now()}`)
  }

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const dispatch: any = useDispatch()

  const handleDelete = async (id: string) => {
    dispatch(deleteMediaAction({id, handleClose}))
  }

  return (
    <div className='mx-4'>
      <div className='row justify-content-center'>
        <div className='col-12 m-auto justify-content-center d-flex d-inline-block'>
          <label
            style={{
              width: '100%',
              fontSize: '16px',
            }}
            htmlFor='servicename'
            className='fw-bold m-auto text-break text-center'
          >
            <span className='mx-1'>{item?.caption}</span>
          </label>
        </div>
        <div className='d-flex col-12 my-auto gx-3 flex-wrap ms-1 mt-1 gy-3 justify-content-center mb-2'>
          {item?.tags?.map(
            (
              tag: {
                name:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                  | React.ReactFragment
                  | React.ReactPortal
                service: {
                  name: string
                }
              },
              idx: React.Key
            ) => (
              <div
                key={idx}
                style={{
                  backgroundColor: '#04bac7',
                  fontSize: '13px',
                  borderRadius: '6px',
                  marginTop: '4px',
                }}
                className={`py-1 px-2 me-1 rounded-pill text-dark`}
              >
                {tag?.service?.name}
              </div>
            )
          )}
        </div>
      </div>
      <div className={`row w-100 m-auto ${true ? 'mt-0' : 'mt-2'}`}>
        <div className='col-6 mx-auto py-2 justify-content-center d-flex'>
          <div className='d-flex align-items-center justify-content-center mx-4'>
            <MdFavorite style={{color: 'red'}} />
            <p style={{fontSize: '16px'}} className='my-0 ms-2'>
              {like?.mediaLikeCounts} Likes
            </p>
          </div>
          <div
            className='d-flex align-items-center justify-content-center mx-4 pointer'
            onClick={downloadFile}
          >
            <FaDownload />
            <p style={{fontSize: '16px'}} className='my-0 ms-2'>
              Download
            </p>
          </div>

          <div
            className='d-flex align-items-center justify-content-center mx-4 pointer'
            onClick={() => {
              handleOpen()
              setDeleteItem(item.id)
            }}
          >
            <MdDelete />
            <p style={{fontSize: '16px'}} className='my-0 ms-2'>
              Delete
            </p>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{zIndex: 9999}}
          >
            <Box sx={style}>
              <Typography
                className='text-center text-white'
                id='modal-modal-title'
                variant='h6'
                component='h2'
              >
                Are you sure to delete it ?
              </Typography>
              <div className='d-flex justify-content-between mt-4 mx-4'>
                <div>
                  {deleteStatus === 'loading' ? (
                    <div className='d-flex justify-content-center btn btn-success'>
                      <div className='spinner-border m-0 p-0' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => {
                        handleDelete(deleteItem)
                      }}
                      type='button'
                      className='btn btn-success'
                      aria-label='submit data'
                    >
                      Yes
                    </button>
                  )}
                </div>
                <div>
                  <button
                    onClick={() => {
                      handleClose()
                    }}
                    type='button'
                    className='btn btn-danger'
                    aria-label='submit data'
                  >
                    No
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default UserProfileFooter
