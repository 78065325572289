import {MDBDataTableV5} from 'mdbreact'
import TableLoading from '../../../_metronic/layout/components/Common/TableSkeletonLoading'
import {DeleteIcon, ViewIcon} from '../../modules/common/TableIcons'
import {Link, useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useAppDispatch} from '../../store'
import {useSelector} from 'react-redux'
import {
  deleteFaqAction,
  faqListSelector,
  faqStatusSelector,
  getAllFaqAction,
} from '../../reducers/faqReducer'
import DeleteModal from './components/DeleteModal'

const FAQ = () => {
  const dispatch: any = useAppDispatch()
  const navigate = useNavigate()

  const faqList = useSelector(faqListSelector)
  const status = useSelector(faqStatusSelector)

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [id, setId] = useState('')

  const handleDelete = (id: string | number) => {
    dispatch(deleteFaqAction(id))
  }

  const [initTable, setInitTable] = useState({
    columns: [
      {
        label: 'Question',
        field: 'question',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows: [],
  })

  useEffect(() => {
    dispatch(
      getAllFaqAction({
        perPage: 50,
        sort: 'id|desc',
      })
    )
  }, [])

  useEffect(() => {
    if (faqList) {
      // console.log('data', faqList)

      const list: any = faqList?.map((item: any) => {
        return {
          id: item.id,
          question: item.question,
          action: [
            <ViewIcon active={true} path={`/faqs/${item.id}`} />,
            <DeleteIcon
              byClick={() => {
                handleOpen()
                setId(item.id)
              }}
              path=''
            />,
          ],
        }
      })
      setInitTable((prevTable) => {
        return {
          ...prevTable,
          rows: list,
        }
      })
    }
  }, [faqList])

  return (
    <>
      <div className='row mb-5'>
        <div className='col-6'>
          <h2 className='py-3 mb-2'>FAQs</h2>
        </div>
        <div className='col-6 align-self-center text-end'>
          <Link to={`/faqs/create`}>
            <button type='button' className='btn btn-primary '>
              Create
            </button>
          </Link>
        </div>
      </div>
      <div
        style={{
          overflow: 'auto',
          whiteSpace: 'nowrap',
        }}
        className='card mb-5 mb-xl-10 p-5'
      >
        {status === 'loading' ? (
          <TableLoading />
        ) : (
          <MDBDataTableV5 hover order={['age', 'desc']} data={initTable} />
        )}
      </div>
      <DeleteModal open={open} handleClose={handleClose} deleteFunc={handleDelete} id={id} />
    </>
  )
}

export default FAQ
