import React, {useEffect, useState} from 'react'
import {makeDate} from '../../../../util'
import {checkAppointmentStatus, formatLocalTime} from '../../../../util/helper'
import {HistoryIcon, ViewIcon} from '../../../modules/common/TableIcons'
import {deleteAppointments} from '../../../../api/endpoints/appointment'
import {useGetAppointmentHistory} from '../hooks/useAppointmentQuery'
import TableLoading from '../../../../_metronic/layout/components/Common/TableSkeletonLoading'
import MUITable from '../../../modules/common/MUITable'
import {useLocation, useParams} from 'react-router-dom'

type AppointmentRow = {
  provider: string
  clientName: string
  date: any
  startingTime: string
  endingTime: string
  duration: string
  fee: string
  paymentStatus: string
  totalPayment: string
  status: string
  tag: string
  id: string
  services: []
  style?: any
}

type service = {
  service: {
    name: string
  }
  id: any
}

const AppointmentHistory = () => {
  const location: any = useLocation()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  // const [previousData, setPreviousData] = useState<any>(location.state)

  const {id}: any = useParams()

  const initTable = {
    columns: [
      // {
      //   label: 'App. Id',
      //   numeric: false,
      //   disablePadding: true,
      //   id: 'id',
      //   sort: 'disabled',
      // },
      {
        label: 'Provider',
        numeric: false,
        disablePadding: true,
        id: 'provider',
      },
      {
        label: 'Client',
        numeric: false,
        disablePadding: true,
        id: 'clientName',
      },
      {
        label: 'Date & Time',
        numeric: false,
        disablePadding: true,
        id: 'dateTime',
      },
      // {
      //   label: 'Services',
      //   numeric: false,
      //   disablePadding: true,
      //   id: 'services',
      // },
      {
        label: 'Status',
        numeric: false,
        disablePadding: true,
        id: 'status',
      },
      {
        label: 'Payment',
        numeric: false,
        disablePadding: true,
        id: 'paymentStatus',
      },
      // {
      //   label: 'Total',
      //   numeric: false,
      //   disablePadding: true,
      //   id: 'totalPayment',
      // },
      // {
      //   label: 'Action',
      //   numeric: false,
      //   disablePadding: true,
      //   id: 'action',
      //   sort: 'disabled',
      // },
    ],
    rows: [],
  }

  const hasDataChanged = (currentData: any, previousData: any): boolean => {
    // console.log('current', currentData != previousData)

    // Compare each field of the current data with the corresponding field of the previous data
    if (currentData !== previousData) {
      return true // Data has changed
    }

    return false // Data has not changed
  }

  const rowItem = (item: AppointmentRow, previousData: AppointmentRow) => {
    // setPreviousData(item)
    // console.log('item', item)
    // console.log('previousData', previousData)

    return {
      // id: item.id,
      provider: [
        <div
          className='d-flex align-items-center'
          style={{
            height: '35px',
          }}
        >
          {hasDataChanged(item.provider, previousData.provider) ? (
            <div className='bg-primary p-1 me-2' style={{height: '100%'}}></div>
          ) : null}

          <div className='text-start'>
            <p className=''>{item.provider}</p>
          </div>
        </div>,
      ],
      clientName: [
        <div
          className='d-flex align-items-center'
          style={{
            height: '35px',
          }}
        >
          {hasDataChanged(item.clientName, previousData.clientName) ? (
            <div className='bg-primary p-1 me-2' style={{height: '100%'}}></div>
          ) : null}

          <div className='text-start'>
            <p className=''>{item.clientName}</p>
          </div>
        </div>,
      ],
      dateTime: [
        <div
          className='d-flex align-items-center'
          style={{
            height: '35px',
          }}
        >
          {hasDataChanged(item.date, previousData.date) ||
          hasDataChanged(item.startingTime, previousData.startingTime) ||
          hasDataChanged(item.endingTime, previousData.endingTime) ? (
            <div className='bg-primary p-1 me-2' style={{height: '100%'}}></div>
          ) : null}
          <div className='text-start'>
            <p className='mb-1'>{item.date ? makeDate(item.date) : '-/-/-'}</p>
            <p className='mb-0'>
              <span>{formatLocalTime(item.startingTime)}</span> -{' '}
              <span>{formatLocalTime(item.endingTime)}</span>
            </p>
          </div>
        </div>,
      ],
      status: [
        <div
          className='d-flex align-items-center'
          style={{
            height: '35px',
          }}
        >
          {hasDataChanged(item.status, previousData.status) ? (
            <div className='bg-primary p-1 me-2' style={{height: '100%'}}></div>
          ) : null}

          <div className='text-start'>
            <span className={`badge ${item.tag}`}>{item.status}</span>
          </div>
        </div>,
      ],
      paymentStatus: [
        <div
          className='d-flex align-items-center'
          style={{
            height: '35px',
          }}
        >
          {hasDataChanged(item.paymentStatus, previousData.paymentStatus) ? (
            <div className='bg-primary p-1 me-2' style={{height: '100%'}}></div>
          ) : null}

          <div className='text-start'>
            <span
              className={`text-uppercase badge ${
                item.paymentStatus ? 'text-success' : 'text-danger'
              }`}
            >
              {item.paymentStatus ? 'Paid' : 'UnPaid'}
            </span>
          </div>
        </div>,
      ],
    }
  }

  const handleDelete = async (id: any) => {
    const del = await deleteAppointments(id)

    return del
  }

  const listSelector = (res: any) => {
    var rows: never[] = []
    let previousData: AppointmentRow = location.state

    let pagination
    // console.log(res)

    pagination = {
      total: res?.total,
      lastPage: res?.lastPage,
    }

    res?.forEach((element: any, index: number) => {
      // setPreviousData(element)

      const {statusToShow, tag}: any = checkAppointmentStatus(
        element.status,
        element.isPaid,
        element.isReservationFee
      )
      const item: AppointmentRow = {
        provider: element?.provider
          ? element?.provider?.firstName + ' ' + element?.provider?.lastName
          : '--',
        clientName: element?.client?.firstName + ' ' + element?.client?.lastName,
        date: element.appointmentDate,
        startingTime: element.startTime,
        endingTime: element.endTime,
        duration: element.totalDuration,
        fee: element.fee,
        paymentStatus: element?.isPaid,
        totalPayment: element.totalPrice,
        status: statusToShow,
        id: element.id,
        tag: tag,
        services: element?.services,
      }
      rows.push(rowItem(item, previousData) as never)
      previousData = item
    })

    var table = initTable
    table.rows = rows
    return {table, pagination}
  }

  const {isLoading, data} = useGetAppointmentHistory({
    func: listSelector,
    params: {appointmentId: id, sort: 'id|desc'},
  })

  return (
    <>
      <div className='row mb-5'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <h2 className='py-3 mb-2'>Appointment history</h2>
          {/* <div>
            <input
              type='search'
              onChange={(e) => {
                if (e.target.value.length >= 3) {
                  setSearch(e.target.value)
                }
              }}
              placeholder='Search'
              className='form-control form-control-sm'
            />
          </div> */}
        </div>
        <div className='col-6 align-self-center text-end'>
          {/* <button type="button" className="btn btn-primary">
              Create
            </button> */}
        </div>
      </div>
      <div
        style={{
          overflow: 'auto',
          whiteSpace: 'nowrap',
        }}
        className='card mb-5 mb-xl-10 p-5'
      >
        {isLoading ? (
          <TableLoading />
        ) : (
          // <MDBDataTableV5 hover order={['age', 'desc']} data={data} />
          <MUITable
            data={data}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        )}
      </div>
    </>
  )
}

export default AppointmentHistory
