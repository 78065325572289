import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addUsersRequest, deleteUsersRequest, getAllUsersRequest} from '../../../api/endpoints/user'
import {RootState} from '../../store'
import {attachedPaginatedPayload} from '../../../util'

interface userState {
  users: Array<any>
  status: string
  error: string | null
  pagination: any
}

export const initialState: userState = {
  users: [],
  pagination: {},
  status: 'idle',
  error: null,
}

export const addUserAction = createAsyncThunk('post/user', async (rData: any) => {
  try {
    const res: any = await addUsersRequest(rData.data)
    const {data, success} = res.data
    if (success) {
      rData.SHOW_SUCCESS(true, 'User added successfully')
      rData.navigate('/users')
    }
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

export const getAllUsersAction = createAsyncThunk('get/all-users', async (params: any) => {
  try {
    const res: any = await getAllUsersRequest(params)
    const {data, success} = res.data
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

export const deleteUserAction = createAsyncThunk('delete/users', async (params: any) => {
  try {
    const res: any = await deleteUsersRequest(params.id)
    const {data, success} = res.data
    if (success) {
      params.handleClose()
    }
    return {
      id: params.id,
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(addUserAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(addUserAction.fulfilled, (state, action) => {
      state.status = 'success'
      // console.log('adara', action.payload)
    })
    builder.addCase(addUserAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(getAllUsersAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getAllUsersAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      state.users = action.payload.data.users
      state.pagination = attachedPaginatedPayload(action.payload.data)
    })
    builder.addCase(getAllUsersAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(deleteUserAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(deleteUserAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      const {id} = action.payload
      state.users = state.users.filter((user: any) => user.id !== id)
    })
    builder.addCase(deleteUserAction.rejected, (state, action) => {
      state.status = 'failed'
    })
  },
})

export const usersListSelector = (state: RootState) => state.users.users
export const usersStatusSelector = (state: RootState) => state.users.status

export default userSlice.reducer
