import React from 'react'
import { convertTime } from '../../../../../../util'

type timeSquareProps = {
  className?: string
  textClassName?: string
  width?: string
  height?: string
  timeStr: string
}

const TimeSquare = ({ timeStr, className, width = '70px', height = '35px', textClassName }: timeSquareProps) => {
  return (
    <div className={`${className}`}>
      <span
        style={{ width: width, height: height }}
        className='symbol symbol-35px bg-white text-center me-2'
      >
        <p className={`fw-bold fs-4 text-primary mt-2 ${textClassName}`}>{convertTime(timeStr).time}</p>
      </span>
      <span
        style={{ width: width, height: height }}
        className='symbol symbol-35px bg-white text-center'
      >
        <p className={`fw-bold fs-4 text-primary mt-2 ${textClassName}`}>{convertTime(timeStr).Meridiem}</p>
      </span>
    </div>
  )
}

export default TimeSquare
