import React from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import * as util from '../../../../../../util'

interface Props {
  tab?: string
  setTab?: any
  data?: any
}

const RoutingTabs = ({tab, setTab, data}: Props) => {
  return (
    <div className='d-flex overflow-auto h-55px'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'profile' ? 'active' : ''
            }`}
            onClick={() => setTab('profile')}
            // to={`/members/${Id}/profile`}
          >
            Profile
          </div>
        </li>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'team' ? 'active' : ''
            }`}
            onClick={() => setTab('team')}
            // to={`/members/${Id}/team`}
          >
            Team
          </div>
        </li>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'appointment' ? 'active' : ''
            }`}
            onClick={() => setTab('appointment')}
            // to={`/members/${Id}/appointment`}
          >
            Appointments
          </div>
        </li>
        <li className='nav-item'>
          <div
            className={`nav-link text-active-primary me-6 cursor-pointer ${
              tab === 'connection' ? 'active' : ''
            }`}
            // to={`/members/${Id}/connections`}
            onClick={() => setTab('connection')}
          >
            Connections
          </div>
        </li>
      </ul>
    </div>
  )
}

export default RoutingTabs
