import {Switch, styled, switchClasses} from '@mui/material'

export const SwitchTextTrack = styled(Switch)({
  width: 70,
  height: 35,
  padding: 8,
  [`& .${switchClasses.switchBase}`]: {
    padding: 4,
    // color: '#ff6a00',
  },
  [`& .${switchClasses.thumb}`]: {
    width: 24,
    height: 24,
    backgroundColor: '#fff',
  },
  [`& .${switchClasses.track}`]: {
    // background: 'linear-gradient(to right, #ee0979, #ff6a00)',
    background: '#f5f5f5',
    opacity: '1 !important',
    borderRadius: 10,
    position: 'relative',
    '&:before, &:after': {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      width: '50%',
      transform: 'translateY(-50%)',
      color: '#fff',
      textAlign: 'center',
      fontSize: '0.5rem',
      fontWeight: 500,
    },
    '&:before': {
      content: '"Approved"',
      left: 4,
      opacity: 0,
    },
    '&:after': {
      content: '"Pending"',
      color: '#000',
      right: 4,
    },
  },
  [`& .${switchClasses.checked}`]: {
    [`&.${switchClasses.switchBase}`]: {
      color: '#185a9d',
      transform: 'translateX(35px)',
      '&:hover': {
        backgroundColor: 'rgba(24,90,257,0.08)',
      },
    },
    [`& .${switchClasses.thumb}`]: {
      backgroundColor: '#fff',
    },
    [`& + .${switchClasses.track}`]: {
      // background: 'linear-gradient(to right, #43cea2, #185a9d)',
      '&:before': {
        opacity: 1,
      },
      '&:after': {
        opacity: 0,
      },
    },
  },
  [`& .${switchClasses.disabled}`]: {
    opacity: 0.5, // Adjust opacity as needed for the disabled state
    pointerEvents: 'none', // Disable pointer events to make the switch unclickable
    [`& + .${switchClasses.track}`]: {
      //   background: '#f5f5f5',
      '&:before': {
      },
      '&:after': {
        color: '#000',
      },
    },
    [`& .${switchClasses.thumb}`]: {
      backgroundColor: '#ccc',
    },
  },
})
