import {Box, Modal, Typography} from '@mui/material'
import modalStyle from './style'
import {BasicModalPropType, ToggleState} from './type'
import {deleteProvider} from '../../../../api'
import {toast} from 'react-toastify'

const DeleteModal = ({toggle, onHide, modelId, label}: BasicModalPropType) => {
  function handleClose() {
    onHide((prev: ToggleState[]) => {
      let modalState = prev.map((curState: ToggleState) => {
        if (curState.name === toggle.name) {
          curState.state = false
        }
        return curState
      })
      return modalState
    })
  }
  console.log('modelId :', toggle?.id)

  function handleDelete(id: string) {
    deleteProvider(id)
      .then(() => {
        toast.success('Provider successfully deleted', {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        console.error('Failed to delete provider:', error)
        toast.error('Something went wrong', {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .finally(handleClose)
  }

  return (
    <Modal
      open={toggle.state}
      onClose={handleClose}
      aria-labelledby='modal-confirm-delete'
      aria-describedby='confirm-delete-options'
    >
      <Box sx={modalStyle}>
        <Typography
          className='text-center text-white'
          id='modal-modal-title'
          variant='h6'
          component='h2'
        >
          Are you sure to delete it ?
        </Typography>
        <div className='d-flex justify-content-between mt-4 mx-4'>
          <div>
            <button
              onClick={() => handleDelete(toggle.id as string)}
              type='button'
              className='btn btn-success'
              aria-label='submit data'
            >
              Yes
            </button>
          </div>
          <div>
            <button
              onClick={handleClose}
              type='button'
              className='btn btn-danger'
              aria-label='submit data'
            >
              No
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default DeleteModal
