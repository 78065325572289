import React from 'react'
import {useMemberTeam} from '../hooks/useMemberQuery'
import {useParams} from 'react-router-dom'
import {UsersListFilter} from '../../../modules/apps/user-management/users-list/components/header/UsersListFilter'
import {MembersTeamCard} from '../../../../_metronic/partials/content/cards/MembersTeamCard'

type Profile = {
  image: string
  Name: string
  parentService: string
  service: string
  id: any
}

interface Props {
  Id?: string
}

const Team = ({Id}: any) => {
  const {isLoading, data} = useMemberTeam(Id as string)

  // console.log('profile', profile)

  return (
    <>
      <div
        style={{minHeight: '60vh'}}
        className={`card mb-5 mb-xl-10 pt-8 ${
          data?.length === 0 && 'd-flex justify-content-center'
        }`}
      >
        {isLoading ? (
          <div className='d-flex justify-content-center my-5'>
            <div className='spinner-grow text-dark' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : data.length === 0 ? (
          <div className='text-center mt-4 mb-5'>
            <h3 className='text-muted'>No matching records found :(</h3>
          </div>
        ) : (
          <div className='row g-6 g-xl-9 p-3'>
            {data?.map((item: any, idx: number) => (
              <div key={idx} className='col-md-3 col-xxl-4'>
                <MembersTeamCard
                  avatar={item?.image === null ? '/media/avatars/blank.png' : item?.image}
                  name={item?.Name}
                  Category={item?.parentService}
                  SubCategory={item?.service}
                  isVerified={item?.isVerified ? true : false}
                  navigates={`/provider/${item?.id}/profile`}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default Team
