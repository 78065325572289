import client from '../client/client'

const addUsersRequest = async (rData: any) => {
  try {
    return await client.post('api/v1/admin/registration', rData)
  } catch (error) {
    return error
  }
}

const getAllUsersRequest = async (params: any) => {
  try {
    return await client.get('api/v1/admin/all-users', {
      params: params,
    })
  } catch (error) {
    return error
  }
}

const deleteUsersRequest = async (id: any) => {
  try {
    return await client.delete(`api/v1/admin/delete/${id}`)
  } catch (error) {
    return error
  }
}

export {addUsersRequest, getAllUsersRequest, deleteUsersRequest}
