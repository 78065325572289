import {combineReducers} from 'redux'
import authReducer from '../reducers/authReducer'
import servicesReducer from './servicesReducer'
import iconReducer from './iconReducer'
import configurationReducer from './configurationReducer'
import userReducer from './userReducer'
import providerReducer from './providerReducer'
import faqReducer from './faqReducer'
import transactionReducer from './transactionsReducer'
import exploreReducer from './exploreReducer'
import paymentSummaryReducer from './paymentSummaryReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  configurations: configurationReducer,
  faq: faqReducer,
  explore: exploreReducer,
  icon: iconReducer,
  service: servicesReducer,
  users: userReducer,
  providers: providerReducer,
  transactions: transactionReducer,
  paymentSummary: paymentSummaryReducer,
})

export default rootReducer
