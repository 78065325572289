import React, { useState } from 'react'
import { HiUsers } from 'react-icons/hi'
import { AiFillPauseCircle } from 'react-icons/ai'
import { IoMdArrowDropdown } from 'react-icons/io'
import TimeSquare from '../TimeSquare'

type dayData = {
    scheduleDays: string
}

const MultiSchedule = ({ data }: any) => {
    const [collapse, setCollapse] = useState(false)
    return (
        <>
            <div className='card'>
                <div
                    className='card-body d-flex flex-end flex-column px-2 py-1 rounded'
                >
                    {/* begin::Modal title */}
                    <div className='container-fluid text-center mt-4'>
                        <div className='d-flex flex-column'>
                            <div className={`${data?.days && data?.isActive ? 'bg-light' : 'disabled-section'} rounded my-3 pb-5 h-100`}>
                                <div onClick={() => setCollapse(!collapse)} className="d-flex justify-content-between hover-primary">
                                    <h5
                                        className='pointer text-gray-800 fw-bolder mb-n4 py-5 text-start ms-4 mb-5 pb-10'
                                    >
                                        {data?.services[0]?.serviceName?.name}
                                    </h5>
                                    <IoMdArrowDropdown className='me-4 mt-2 pointer' size={'2rem'} />
                                </div>
                                <div className='row mx-2 align-items-end'>
                                    <div className='col-3 text-start my-auto'>
                                        <span className='fw-bold d-block'>No of Participants</span>
                                        <div className="d-flex align-items-center">
                                            <p className='mb-0 fw-bold me-2 '>
                                                {data?.services[0]?.noOfParticipants}
                                            </p>
                                            <HiUsers size={'1.1rem'} />
                                        </div>
                                    </div>
                                    <div className='col-4 text-center'>
                                        <div className="d-flex">
                                            {data?.days?.map(({ scheduleDays }: dayData) => (
                                                <span className='symbol symbol-35px text-center bg-primary w-30px m-1'>
                                                    <p className={`fw-bold fs-7 text-white my-2`}>
                                                        {
                                                            scheduleDays === "Thursday" ? "TH"
                                                                : scheduleDays === "Sunday" ? "SU" : scheduleDays.slice(0, 1)
                                                        }
                                                    </p>
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-5 text-start d-flex align-items-end'>
                                        <div className='row mx-0 px-0'>
                                            <p className='fw-bold mb-2 text-center'>Slot Time</p>
                                            <div className='col-6 text-center my-auto px-0 mx-0'>
                                                <TimeSquare timeStr={data?.startTime} width='50px' height='30px' className='align-items-center d-flex justify-content-center' textClassName='fs-6 my-0' />
                                            </div>
                                            <div className='col-6 text-center my-auto px-0 mx-0'>
                                                <TimeSquare timeStr={data?.endTime} width='50px' height='30px' className='align-items-center d-flex justify-content-center' textClassName='fs-6 my-0' />
                                            </div>
                                        </div>
                                        <AiFillPauseCircle className='ms-3' size={'3rem'} />
                                    </div>
                                </div>
                            </div>
                            {collapse &&
                                <>
                                    <div className="row my-3">
                                        <div className="col-lg-6">
                                            {/* Break Blocks section */}
                                            <div className={`${data?.breakTimes.length > 0 && data?.isActive ? 'bg-light' : 'disabled-section'} rounded pb-5 h-100`}>
                                                <h5
                                                    className='text-gray-800 fw-bolder mb-n4 py-5 pointer text-start ms-4 mb-5 pb-10'
                                                >
                                                    Break Blocks
                                                </h5>
                                                <div>
                                                    {data?.breakTimes?.map(
                                                        ({ endTime, startTime }: { endTime: string; startTime: string }, idx: number) => (
                                                            <span key={idx}>
                                                                <div className='row mx-2'>
                                                                    <div className='col-6 text-start my-auto'>
                                                                        <span className='fw-bold'>Break Begin</span>
                                                                    </div>
                                                                    <TimeSquare className='col-6 text-end' timeStr={startTime} />
                                                                </div>
                                                                <div className='row mx-2 my-2'>
                                                                    <div className='col-6 text-start my-auto'>
                                                                        <span className='fw-bold'>Break End</span>
                                                                    </div>
                                                                    <TimeSquare className='col-6 text-end' timeStr={endTime} />
                                                                </div>
                                                            </span>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {/* Reservation Fee section */}
                                            <div className={`${data?.reservationFee && data?.isActive ? 'bg-light' : 'disabled-section'} rounded pb-5 h-100`}>
                                                <h5
                                                    className='text-gray-800 fw-bolder mb-n4 py-5 pointer text-start ms-4 mb-5 pb-10'
                                                >
                                                    Reservation Fee
                                                </h5>
                                                <>
                                                    <div className='row mx-2'>
                                                        <div className='col-6 text-start my-auto'>
                                                            <span className='fw-bold'>Fee</span>
                                                        </div>
                                                        <div className='col-6 text-end'>
                                                            <span
                                                                style={{ width: '70px', height: '35px' }}
                                                                className='symbol symbol-35px bg-white text-center'
                                                            >
                                                                <p className='fw-bold fs-6 text-primary mt-2'>${data?.reservationFee}</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='row mx-2 mt-5'>
                                                        <div className='col-6 text-start my-auto'>
                                                            <span className='fw-bold'>Repeat</span>
                                                        </div>
                                                        <div className='col-6 text-end'>
                                                            <span
                                                                style={{ width: '70px', height: '35px' }}
                                                                className='symbol symbol-35px bg-white text-center'
                                                            >
                                                                <p className='fw-bold fs-6 text-primary mt-2'>Yes</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-lg-6">
                                            {/*  Last Minute Booking */}
                                            <div className={`${true && data?.isActive ? 'bg-light' : 'disabled-section'} rounded pb-5 h-100`}>
                                                <h5
                                                    className='text-gray-800 fw-bolder mb-n4 py-5 pointer text-start ms-4 mb-5 pb-10'
                                                >
                                                    Allow Last Minute Booking
                                                </h5>
                                                <>
                                                    <div className='row mx-2'>
                                                        <div className='col-6 text-start my-auto'>
                                                            <span className='fw-bold'>Upto</span>
                                                        </div>
                                                        <div className='col-6 text-end'>
                                                            <span
                                                                style={{ width: '70px', height: '35px' }}
                                                                className='symbol symbol-35px bg-white text-center'
                                                            >
                                                                <p className='fw-bold fs-6 text-primary mt-2'>30 Min</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='row mx-2 mt-5'>
                                                        <div className='col-6 text-start my-auto'>
                                                            <span className='fw-bold'>Appointment</span>
                                                        </div>
                                                        <div className='col-6 text-end'>
                                                            <span
                                                                style={{ width: '70px', height: '35px' }}
                                                                className='symbol symbol-35px bg-white text-center'
                                                            >
                                                                <p className='fw-bold fs-6 text-primary mt-2'>30 Min</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {/* Peak Pricing */}
                                            <div className={`${data?.monthlySchedulePercentage && data?.isActive ? 'bg-light' : 'disabled-section'} rounded pb-5 h-100`}>
                                                <h5
                                                    className='text-gray-800 fw-bolder mb-n4 py-5 pointer text-start ms-4 mb-5 pb-10'
                                                >
                                                    Peak Pricing
                                                </h5>
                                                <>
                                                    <div className='row mx-2'>
                                                        <div className='col-5 text-start my-auto'>
                                                            <span className='fw-bold'>Monthly Schedule</span>
                                                        </div>
                                                        <div className='col-7 text-end'>
                                                            <span
                                                                style={{ width: '70px', height: '35px' }}
                                                                className='symbol symbol-35px bg-white text-center'
                                                            >
                                                                <p className='fw-bold fs-6 text-primary mt-2'>
                                                                    {data?.monthlySchedulePercentage}%
                                                                </p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='row mx-2 mt-5'>
                                                        <div className='col-5 text-start my-auto'>
                                                            <span className='fw-bold'>Increase Rate</span>
                                                        </div>
                                                        <div className='col-7 text-end'>
                                                            <span
                                                                style={{ width: '70px', height: '35px' }}
                                                                className='symbol symbol-35px bg-white text-center'
                                                            >
                                                                <p className='fw-bold fs-6 text-primary mt-2'>{data?.increaseRateBy} %</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='row mx-2 mt-5'>
                                                        <div className='col-5 text-start my-auto'>
                                                            <span className='fw-bold'>VIC Excempt</span>
                                                        </div>
                                                        <div className='col-7 text-end'>
                                                            <span
                                                                style={{ width: '70px', height: '35px' }}
                                                                className='symbol symbol-35px bg-white text-center'
                                                            >
                                                                <p className='fw-bold fs-6 text-primary mt-2'>
                                                                    {data?.isVicExempt ? 'Yes' : 'No'}
                                                                </p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MultiSchedule