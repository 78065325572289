export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  // console.log('current url =>  ', pathname.split(/[?#]/)[0], '\n url => ', url)

  const re = /.*\/+\d*(?:\/+\d+)*$/gm

  if (!current || !url) {
    return false
  }

  if (current === url) {
    if (!re.test(pathname)) {
      return true
    }
  }

  if (current.indexOf(url) > -1) {
    // console.log(current.indexOf(url))
    // console.log(url)
    // console.log('current =>', )
    if (!re.test(pathname)) {
      return true
    }
  }

  return false
}
