import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  getConfigurationByKeyRequest,
  getConfigurations,
  updateConfigurationsRequest,
} from '../../../api/endpoints/configuration'
import {RootState} from '../../store'
import {SHOW_SUCCESS, attachedPaginatedPayload} from '../../../util'

interface configurationPayload {
  id: number | string | null
  key: string | null
  type: string | null
  value: any | null
}

interface configurationState {
  configurations: Array<any>
  configuration: any
  pagination: any
  status: string
  singleStatus: string
  error: string | null
}

export const initialState: configurationState = {
  configurations: [],
  configuration: {},
  pagination: {},
  status: 'idle',
  singleStatus: 'idle',
  error: null,
}

export const getConfigurationsAction = createAsyncThunk(
  'get/configurations',
  async (params: any) => {
    try {
      const res: any = await getConfigurations(params)
      const {data, success, message} = res.data
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {
      console.log('get configuration error', error)
      return error
    }
  }
)

export const getConfigurationByKeyAction = createAsyncThunk(
  'get/configuration-by-key',
  async (key: string) => {
    try {
      const res: any = await getConfigurationByKeyRequest(key)
      const {data, success, message} = res.data
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {
      console.log('get configuration error', error)
      return error
    }
  }
)

export const updateConfigurationAction = createAsyncThunk(
  'update/configurations',
  async (formData: any) => {
    try {
      const res: any = await updateConfigurationsRequest(formData)
      const {data, success, message} = res.data
      if (success) {
        SHOW_SUCCESS(true, 'Configuration updated successfully')
      }
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {
      console.log('configuration update error', error)
      return error
    }
  }
)

const configurationSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    updateNotificationState: (state, {payload}) => {
      state.configuration = {
        ...state.configuration,
        value: {
          ...state.configuration.value,
          [payload.name]: payload.data,
        },
      }
    },

    updateJson2State: (state, {payload}: any) => {
      // console.log('data', payload)
      state.configuration = {
        ...state.configuration,
        value: {
          ...state.configuration.value,
          [payload?.key]: payload?.methods,
        },
      }
    },

    updateArrayState: (state, {payload}: any) => {
      // console.log('data', payload)
      const {idx, value} = payload

      state.configuration.value[idx] = value
    },

    addRemoveEntryInArrayState: (state, {payload}: any) => {
      state.configuration.value = payload
    },

    updateJson5State: (state, {payload}) => {
      // console.log('data', payload)

      state.configuration = {
        ...state.configuration,
        value: payload,
      }
    },

    updateIntegerState: (state, {payload}) => {
      state.configuration = {
        ...state.configuration,
        value: payload,
      }
    },

    updateDisableScheduleDaysState: (state, {payload}) => {
      state.configuration = {
        ...state.configuration,
        value: payload,
      }
    },

    updateJson3State: (state, {payload}) => {
      state.configuration = {
        ...state.configuration,
        value: payload,
      }
    },

    updateJson4State: (state, {payload}) => {
      const {index, key, value} = payload

      state.configuration.value[index] = {
        ...state.configuration.value[index],
        [key]: value,
      }
    },

    updateJson1: (state, {payload}) => {
      const {idx, key, value, parentKey} = payload
      // console.log('data', key)

      state.configuration.value[parentKey][idx] = {
        ...state.configuration.value[parentKey][idx],
        [key]: value,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConfigurationsAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getConfigurationsAction.fulfilled, (state, action) => {
      const {data, success, code}: any = action.payload
      state.configurations = data.configurations
      state.pagination = attachedPaginatedPayload(data)
      state.status = 'success'
    })
    builder.addCase(getConfigurationsAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(updateConfigurationAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(updateConfigurationAction.fulfilled, (state, action) => {
      state.status = 'success'
      const {data}: any = action.payload
      // console.log('data', data)
    })
    builder.addCase(updateConfigurationAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(getConfigurationByKeyAction.pending, (state, action) => {
      state.singleStatus = 'loading'
    })
    builder.addCase(getConfigurationByKeyAction.fulfilled, (state, action) => {
      const {data, success, code}: any = action.payload
      state.configuration = data
      state.singleStatus = 'success'
    })
    builder.addCase(getConfigurationByKeyAction.rejected, (state, action) => {
      state.singleStatus = 'failed'
    })
  },
})
export const {
  updateNotificationState,
  updateJson2State,
  updateIntegerState,
  updateJson3State,
  updateJson4State,
  updateJson1,
  updateJson5State,
  updateDisableScheduleDaysState,
  updateArrayState,
  addRemoveEntryInArrayState,
} = configurationSlice.actions

export default configurationSlice.reducer

export const configurationsSelector = (state: RootState) => state.configurations.configurations
export const singleConfigurationSelector = (state: RootState) => state.configurations.configuration
export const configurationStatusSelector = (state: RootState) => state.configurations.status
export const singleConfigurationStatusSelector = (state: RootState) =>
  state.configurations.singleStatus
