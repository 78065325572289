import {useNavigate} from 'react-router-dom'
import {BsEyeFill} from 'react-icons/bs'
import {BiEditAlt} from 'react-icons/bi'
import {AiFillLock} from 'react-icons/ai'
import {RiDeleteBin6Line} from 'react-icons/ri'
import {MdHistory} from 'react-icons/md'

type Props = {
  path: string
  byClick?: any
  active?: boolean
  color?: string
  data?: any
}

const ViewIcon = ({path, byClick, data, active, color}: Props) => {
  const navigate = useNavigate()

  return (
    <>
      <span
        onClick={() => navigate(path, {state: data})}
        // to={path}
        className={`pointer text-dark py-3 px-5 hover-blue ms-n5 bg-transparent ${
          !active && 'not-allowed'
        }`}
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        title={active ? 'View' : 'Inactive'}
      >
        <BsEyeFill onClick={byClick} size={'1.1rem'} color={!active ? 'rgb(185 191 197)' : color} />
      </span>
    </>
  )
}

const HistoryIcon = ({path, byClick, data}: Props) => {
  const navigate = useNavigate()
  return (
    <>
      <span
        onClick={() => navigate(path, {state: data})}
        className='pointer text-dark px-5 py-3 hover-green bg-transparent'
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        title='History'
      >
        <MdHistory onClick={byClick} size={'1.1rem'} />
      </span>
    </>
  )
}

const EditIcon = ({path, byClick}: Props) => {
  const navigate = useNavigate()
  return (
    <>
      <span
        onClick={() => navigate(path)}
        className='pointer text-dark px-5 py-3 hover-green bg-transparent'
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        title='Edit'
      >
        <BiEditAlt onClick={byClick} size={'1.1rem'} />
      </span>
    </>
  )
}

const LockIcon = ({byClick}: Props) => {
  // const navigate = useNavigate()
  return (
    <>
      <span
        onClick={byClick}
        className='pointer text-dark hover-green px-5 py-3 bg-transparent'
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        title='Update Password'
      >
        <AiFillLock size={'1.1rem'} />
      </span>
    </>
  )
}

const DeleteIcon = ({path, byClick}: Props) => {
  return (
    <>
      <span
        onClick={byClick}
        // onClick={() => navigate(path)}
        className='pointer text-dark hover-danger px-5 py-3 bg-transparent'
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        title='Delete'
      >
        <RiDeleteBin6Line size={'1.1rem'} />
      </span>
    </>
  )
}

export {ViewIcon, EditIcon, LockIcon, DeleteIcon, HistoryIcon}
