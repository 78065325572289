import React, {useEffect, useState} from 'react'
import TableLoading from '../../../_metronic/layout/components/Common/TableSkeletonLoading'
import {deleteAppointments} from '../../../api/endpoints/appointment'
import {useAppointmentList} from './hooks/useAppointmentQuery'
import {checkAppointmentStatus, makeDate} from '../../../util'
import {formatLocalTime} from '../../../util/helper'
import {HistoryIcon, ViewIcon} from '../../modules/common/TableIcons'
import MUITable from '../../modules/common/MUITable'
import {AppointmentListFilter} from './components/AppointmentlistFilter'
import {useMemberList} from '../Member/hooks/useMemberQuery'
import {useProviderSList} from '../Provider/hooks/useProviderQuery'
import {useLocation} from 'react-router-dom'

type AppointmentRow = {
  provider: string
  clientName: string
  date: any
  startingTime: string
  endingTime: string
  duration: string
  fee: string
  paymentStatus: string
  totalPayment: string
  status: string
  tag: string
  id: string
  services: []
}

type ProviderRow = {
  profilePic: string
  firstName: string
  lastName: string
  phone: string
  businessName: string
  status: string
  id: string
  timezone: string
  isVerified: boolean
}

type MemberRow = {
  profilePic: string
  firstName: string
  lastName: string
  phone: string
  lastLogin: any
  status: string
  id: string
  timezone: string
  isVerified: boolean
}

type service = {
  service: {
    name: string
  }
  id: any
}

const Appointments = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const queryParams = new URLSearchParams(useLocation().search)

  const [searchParams, setSearchParams] = useState<any>({
    status: queryParams.get('status') ? 'approved,approveByProvider' : '',
  })
  // console.log('searchParams', queryParams.get('status'))

  const initTable = {
    columns: [
      {
        label: 'App. Id',
        numeric: false,
        disablePadding: true,
        id: 'id',
        sort: 'disabled',
      },
      {
        label: 'Provider',
        numeric: false,
        disablePadding: true,
        id: 'provider',
      },
      {
        label: 'Client',
        numeric: false,
        disablePadding: true,
        id: 'clientName',
      },
      {
        label: 'Date & Time',
        numeric: false,
        disablePadding: true,
        id: 'dateTime',
      },
      {
        label: 'Services',
        numeric: false,
        disablePadding: true,
        id: 'services',
      },
      {
        label: 'Status',
        numeric: false,
        disablePadding: true,
        id: 'status',
      },
      {
        label: 'Payment',
        numeric: false,
        disablePadding: true,
        id: 'paymentStatus',
      },
      {
        label: 'Total',
        numeric: false,
        disablePadding: true,
        id: 'totalPayment',
      },
      {
        label: 'Action',
        numeric: false,
        disablePadding: true,
        id: 'action',
        sort: 'disabled',
      },
    ],
    rows: [],
  }

  const rowItem = (item: AppointmentRow) => {
    return {
      id: item.id,
      provider: item.provider,
      clientName: item.clientName,
      dateTime: [
        <div className='text-start'>
          <p className='mb-1'>{item.date ? makeDate(item.date) : '-/-/-'}</p>
          <p className='mb-0'>
            <span>{formatLocalTime(item.startingTime)}</span> -{' '}
            <span>{formatLocalTime(item.endingTime)}</span>
          </p>
        </div>,
      ],
      services: (
        <div className='text-wrap'>
          {item.services?.slice(0, 3).map((data: service, index: number) => (
            <span
              key={index}
              className={`badge badge-secondary me-1 ${
                index !== item.services?.length - 1 ? 'mb-1' : ''
              }`}
            >
              {data.service?.name}
            </span>
          ))}
          {item.services?.length > 2 ? '...' : ''}
        </div>
      ),
      status: [<span className={`badge ${item.tag}`}>{item.status}</span>],
      paymentStatus: [
        <span
          className={`text-uppercase badge ${item.paymentStatus ? 'text-success' : 'text-danger'}`}
        >
          {item.paymentStatus ? 'Paid' : 'UnPaid'}
        </span>,
      ],
      totalPayment: [item.totalPayment ? <span> ${item.totalPayment}</span> : null],
      action: [
        <ViewIcon path={`/appointments/${item.id}`} active={true} />,
        <HistoryIcon path={`/appointments/${item.id}/history`} data={item} />,
        // <DeleteIcon byClick={() => handleDelete(item.id)} path='#' />,
      ],
    }
  }

  const handleDelete = async (id: any) => {
    const del = await deleteAppointments(id)

    return del
  }

  const listSelector = (res: any) => {
    var rows: never[] = []

    let pagination
    // console.log(res)

    pagination = {
      total: res.total,
      lastPage: res.lastPage,
    }

    res.appointments.forEach((element: any, index: number) => {
      const {statusToShow, tag}: any = checkAppointmentStatus(
        element.status,
        element.isPaid,
        element.isReservationFee
      )
      const item: AppointmentRow = {
        provider: element?.provider
          ? element?.provider?.id
            ? element?.provider?.firstName + ' ' + element?.provider?.lastName
            : element?.provider?.firstName + ' ' + element?.provider?.lastName + ' (deleted)'
          : '--',
        clientName: element?.client?.id
          ? element?.client?.firstName + ' ' + element?.client?.lastName
          : element?.client?.firstName + ' ' + element?.client?.lastName + ' (deleted)',
        date: element.appointmentDate,
        startingTime: element.startTime,
        endingTime: element.endTime,
        duration: element.totalDuration,
        fee: element.fee,
        paymentStatus: element?.isPaid,
        totalPayment: element.totalPrice,
        status: statusToShow,
        id: element.id,
        tag: tag,
        services: element?.services,
      }
      rows.push(rowItem(item) as never)
    })

    var table = initTable
    table.rows = rows
    return {table, pagination}
  }

  const {isLoading, data} = useAppointmentList({
    func: listSelector,
    params: {
      page,
      perPage,
      ...searchParams,
    },
  })

  const memberListSelector = (res: any) => {
    let rows: never[] = []

    res?.clients?.forEach((element: any) => {
      const item: MemberRow = {
        profilePic: element?.profileImage,
        firstName: element?.firstName,
        lastName: element?.lastName,
        phone: element?.mobileNo,
        lastLogin: element?.lastLogin,
        status: element?.status,
        timezone: element?.timezone,
        isVerified: element?.isMobileVerified,
        id: element?.id,
      }
      rows.push(item as never)
    })

    return rows
  }

  const {data: MemberData} = useMemberList({
    func: memberListSelector,
  })

  const providerListSelector = (res: any) => {
    let rows: never[] = []

    res?.providers?.forEach((element: any) => {
      const item: ProviderRow = {
        profilePic: element?.profileImage,
        firstName: element?.firstName,
        lastName: element?.lastName,
        phone: element?.mobileNo,
        businessName: element?.businessName,
        status: element?.status,
        timezone: element?.timezone,
        isVerified: element?.isMobileVerified,
        id: element?.id,
      }
      rows.push(item as never)
    })

    return rows
  }

  const {data: providerData} = useProviderSList({
    func: providerListSelector,
  })

  // useEffect(() => {
  //   var rows: never[] = []
  //   const item: AppointmentRow = {
  //     no: 1,
  //     provider: 'Smith',
  //     clientName: 'Max',
  //     date: '3 July 2022',
  //     startingTime: '9:00 AM',
  //     endingTime: '9:00 AM',
  //     duration: '2 hours',
  //     fee: '$400',
  //     paymentStatus: 'Unpaid',
  //     totalPayment: '$700',
  //     status: 'Active',
  //     id: '3',
  //   }
  //   rows.push(rowItem(item) as never)
  //   var table = initTable
  //   table.rows = rows
  //   setDatable(table as any)
  // }, [])

  return (
    <>
      <div className='row mb-5'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <h2 className='py-3 mb-2'>Appointments</h2>
          <div className='row'>
            <div className='col-12 text-end'>
              <AppointmentListFilter
                memberData={MemberData}
                setPage={setPage}
                providerData={providerData}
                setSearchParams={setSearchParams}
                searchParams={searchParams}
              />
            </div>
          </div>
        </div>
        <div className='col-6 align-self-center text-end'>
          {/* <button type="button" className="btn btn-primary">
              Create
            </button> */}
        </div>
      </div>
      <div
        style={{
          overflow: 'auto',
          whiteSpace: 'nowrap',
        }}
        className='card mb-5 mb-xl-10 p-5'
      >
        {isLoading ? (
          <TableLoading />
        ) : (
          // <MDBDataTableV5 hover order={['age', 'desc']} data={data} />
          <MUITable
            data={data}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        )}
      </div>
    </>
  )
}

export {Appointments}
