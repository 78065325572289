import ProfileOverview from './components/Overview'
import RoutingTabs from './components/RoutingTabs'

interface Props {
  tab?: string
  setTab?: any
  data?: any
}

const MemberHeader = ({tab, setTab, data}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <ProfileOverview data={data} />
        <RoutingTabs tab={tab} setTab={setTab} data={data} />
      </div>
    </div>
  )
}

export {MemberHeader}
