import {useCallback} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {
  getProvider2ndServicesRequest,
  getProviderAppointmentsRequest,
  getProviderCredentialsRequest,
  getProviderMediaRequest,
  getProviderProfileRequest,
  getProviderScheduleRequest,
  getProviderServicesRequest,
  getProviderVacationRequest,
  getProvidersListRequest,
  getProvidersMembersRequest,
  updateProviderStatusRequest,
} from '../../../../api'
import {percentage} from '../../../../util'

// const queryKey = 'my-query-key'
type useProductsParams = Parameters<typeof getProviderProfileRequest>

export const useProviderProfile = (...params: useProductsParams) => {
  return useQuery({
    queryKey: 'providerProfile',
    queryFn: () => getProviderProfileRequest(...params),
    cacheTime: 0,
    retry: false,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const progressValues: Array<number> = [
        data.services,
        data.schedules,
        data.isMedias,
        data.credentials,
        data.payments,
      ]
      const newData = {...data, percentage: percentage(progressValues, progressValues.length)}
      return newData
    },
  })
}

export const useProviderSList = (selectorFun: any) => {
  return useQuery({
    queryKey: ['providerList', selectorFun.params],
    queryFn: () => getProvidersListRequest(selectorFun.params),
    select: selectorFun.func,
    refetchOnWindowFocus: false,
    // keepPreviousData: true,
  })
}

export const useProviderStatusUpdate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateProviderStatusRequest,
    onSuccess: (data) => {
      // console.log('data', data)
      queryClient.invalidateQueries({queryKey: ['providerList']})
    },
  })
}

export const useProviderMember = (id: string, selectorFun: any) => {
  return useQuery({
    queryKey: 'providerMember',
    queryFn: () => getProvidersMembersRequest(id),
    select: selectorFun,
    cacheTime: 0,
  })
}

export const useProviderService = (id: string, selectorFun: any) => {
  return useQuery({
    queryKey: 'providerService',
    queryFn: () => getProviderServicesRequest(id),
    select: selectorFun,
    cacheTime: 0,
  })
}
export const useProviderSecondLvlService = (data: any) => {
  return useQuery({
    queryKey: 'providerSecondLvlService',
    queryFn: () => getProvider2ndServicesRequest(data),
    cacheTime: 0,
  })
}

export const useProviderThirdLvlService = (data: any, selectorFun: any) => {
  return useQuery({
    queryKey: 'providerThirdLvlService',
    queryFn: () => getProvider2ndServicesRequest(data),
    select: selectorFun,
    cacheTime: 0,
  })
}

export const useProviderAppointment = (id: string, selectorFun: any) => {
  return useQuery({
    queryKey: 'providerAppointment',
    queryFn: () => getProviderAppointmentsRequest(id),
    select: selectorFun,
    cacheTime: 0,
  })
}

export const useProviderSchedule = (id: string) => {
  return useQuery({
    queryKey: 'providerSchedule',
    queryFn: () => getProviderScheduleRequest(id),
    cacheTime: 0,
  })
}

export const useProviderVacation = (id: string) => {
  return useQuery({
    queryKey: 'providerVacation',
    queryFn: () => getProviderVacationRequest(id),
    cacheTime: 0,
  })
}

export const useProviderMedia = (id: string) => {
  return useQuery({
    queryKey: 'providerMedia',
    queryFn: () => getProviderMediaRequest(id),
    cacheTime: 0,
  })
}

export const useProviderCredential = (id: string) => {
  return useQuery({
    queryKey: 'providerCredential',
    queryFn: () => getProviderCredentialsRequest(id),
    cacheTime: 0,
  })
}
