/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { AiFillPauseCircle } from 'react-icons/ai'
import TimeSquare from './TimeSquare'

type Props = {
    data?: any
}

type dayData = {
    abb: String
    hasIt: boolean
}

const OnetoOne: FC<Props> = ({ data }) => {

    return (
        <>
            <div className='card'>
                <div className='container-fluid text-center mt-4'>
                    <div className='d-flex flex-column'>
                        {/* busy days section */}
                        <div className={`${data?.days && data?.isActive ? 'bg-light' : 'disabled-section'} rounded my-3 pb-5`}>
                            <h5 className='text-gray-800 fw-bolder mb-n4 py-5 text-start ms-4 mb-5 pb-10'>
                                Days
                            </h5>
                            <div className='row justify-content-center mx-4'>
                                {data?.days?.map(({ abb, hasIt }: dayData, idx: any) => (
                                    <div
                                        key={idx}
                                        className={`col ${hasIt ? 'bg-primary' : 'bg-white'} rounded m-1`}
                                    >
                                        <span className='symbol symbol-35px text-center'>
                                            <p
                                                className={`fw-bold fs-4 ${hasIt ? 'text-white' : 'text-primary'} my-2`}
                                            >
                                                {abb}
                                            </p>
                                        </span>
                                    </div>
                                ))}

                            </div>

                            <div className='row mx-2 mt-7'>
                                <div className='col-6 text-start my-auto text-center'>
                                    <p className='fw-bold mb-4 mt-2'>Day Begin</p>
                                    <TimeSquare timeStr={data?.startTime} />
                                </div>
                                <div className='col-6 text-start my-auto text-center'>
                                    <p className='fw-bold mb-4 mt-2'>Day End</p>
                                    <TimeSquare timeStr={data?.endTime} />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-stretch my-3">
                            <div className="col-lg-6">
                                <div className={`${data?.services && data?.isActive ? 'bg-light' : 'disabled-section'} h-100 rounded pb-5`}>
                                    {/* service section */}
                                    <h5 className='text-gray-800 fw-bolder mb-n4 py-5 text-start ms-4 mb-5 pb-10'>
                                        Services
                                    </h5>
                                    <div className='h-250px overflow-auto'>
                                        {data?.services?.map(({ serviceName }: any, idx: number) => (
                                            <div key={idx} className={`row mx-2 ${idx !== 0 && 'mt-5'}`}>
                                                <div className='col-6 text-start my-auto'>
                                                    <span className='fw-bold'>{serviceName?.name}</span>
                                                </div>
                                                <div className='col-6 text-end'>
                                                    <span
                                                        style={{ width: '70px', height: '35px' }}
                                                        className='symbol symbol-35px bg-white text-center'
                                                    >
                                                        <p className='fw-bold fs-6 text-primary mt-2'>
                                                            {serviceName?.duration} Min
                                                        </p>
                                                    </span>
                                                    <AiFillPauseCircle className='ms-3' size={'2rem'} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={`${data?.breakTimes && data?.isActive ? 'bg-light' : 'disabled-section'} h-100 rounded pb-5`}>
                                    {/* Break Blocks section */}
                                    <h5
                                        className='text-gray-800 fw-bolder mb-n4 py-5 text-start ms-4 mb-5 pb-10'
                                    >
                                        Break Blocks
                                    </h5>
                                    <div>
                                        {data?.breakTimes?.map(
                                            (
                                                { endTime, startTime }: { endTime: string; startTime: string },
                                                idx: number
                                            ) => (
                                                <span key={idx}>
                                                    <div className={`row mx-2 ${idx !== 0 && 'mt-7'}`}>
                                                        <div className='col-6 text-center my-auto'>
                                                            <p className='fw-bold mb-4'>Break Begin</p>
                                                            <TimeSquare timeStr={startTime} />
                                                        </div>
                                                        <div className='col-6 text-center my-auto'>
                                                            <p className='fw-bold mb-4'>Break End</p>
                                                            <TimeSquare timeStr={endTime} />
                                                        </div>
                                                    </div>
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-stretch my-3">
                            <div className="col-lg-6">
                                {/* Double Booking section */}
                                <div className={`${data?.overlapping_time && data?.isActive ? 'bg-light' : 'disabled-section'} rounded pb-5`}>
                                    <h5
                                        className='text-gray-800 fw-bolder mb-n4 py-5 text-start ms-4 mb-5 pb-10'
                                    >
                                        Allow for Double Booking
                                    </h5>

                                    <div>
                                        <div className='row mx-2'>
                                            <div className='col-6 text-start my-auto'>
                                                <span className='fw-bold'>Overlaping Time</span>
                                            </div>
                                            <div className='col-6 text-end'>
                                                <span
                                                    style={{ width: '70px', height: '35px' }}
                                                    className='symbol symbol-35px bg-white text-center'
                                                >
                                                    <p className='fw-bold fs-6 text-primary mt-2'>
                                                        {data?.overlapping_time} Min
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row mx-2 mt-5'>
                                            <div className='col-6 text-start my-auto'>
                                                <span className='fw-bold'>All Services</span>
                                            </div>
                                            <div className='col-6 text-end'>
                                                <span
                                                    style={{ width: '70px', height: '35px' }}
                                                    className='symbol symbol-35px bg-white text-center'
                                                >
                                                    <p className='fw-bold fs-6 text-primary mt-2'>
                                                        {data?.allServices ? 'Yes' : 'No'}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {/* Reservation Fee section */}
                                <div className={`${data?.reservationFee && data?.isActive ? 'bg-light' : 'disabled-section'} rounded pb-5`}>
                                    <h5
                                        className='text-gray-800 fw-bolder mb-n4 py-5 text-start ms-4 mb-5 pb-10'
                                    >
                                        Reservation Fee
                                    </h5>
                                    <>
                                        <div className='row mx-2'>
                                            <div className='col-6 text-start my-auto'>
                                                <span className='fw-bold'>Fee</span>
                                            </div>
                                            <div className='col-6 text-end'>
                                                <span
                                                    style={{ width: '70px', height: '35px' }}
                                                    className='symbol symbol-35px bg-white text-center'
                                                >
                                                    <p className='fw-bold fs-6 text-primary mt-2'>
                                                        ${data?.reservationFee}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row mx-2 mt-5'>
                                            <div className='col-6 text-start my-auto'>
                                                <span className='fw-bold'>Repeat</span>
                                            </div>
                                            <div className='col-6 text-end'>
                                                <span
                                                    style={{ width: '70px', height: '35px' }}
                                                    className='symbol symbol-35px bg-white text-center'
                                                >
                                                    <p className='fw-bold fs-6 text-primary mt-2'>Yes</p>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-stretch my-3">
                            <div className="col-lg-6">
                                {/*  Last Minute Booking */}
                                <div className={`${true && data?.isActive ? 'bg-light' : 'disabled-section'} rounded h-100`}>
                                    <h5
                                        className='text-gray-800 fw-bolder mb-n4 py-5 text-start ms-4 mb-5 pb-10'
                                    >
                                        Allow Last Minute Booking
                                    </h5>
                                    <>
                                        <div className='row mx-2'>
                                            <div className='col-6 text-start my-auto'>
                                                <span className='fw-bold'>Upto</span>
                                            </div>
                                            <div className='col-6 text-end'>
                                                <span
                                                    style={{ width: '70px', height: '35px' }}
                                                    className='symbol symbol-35px bg-white text-center'
                                                >
                                                    <p className='fw-bold fs-6 text-primary mt-2'>30 Min</p>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row mx-2 mt-5'>
                                            <div className='col-6 text-start my-auto'>
                                                <span className='fw-bold'>Appointment</span>
                                            </div>
                                            <div className='col-6 text-end'>
                                                <span
                                                    style={{ width: '70px', height: '35px' }}
                                                    className='symbol symbol-35px bg-white text-center'
                                                >
                                                    <p className='fw-bold fs-6 text-primary mt-2'>30 Min</p>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {/* Peak Pricing */}
                                <div className={`${data?.monthlySchedulePercentage && data?.isActive ? 'bg-light' : 'disabled-section'} rounded h-100 pb-4`}>
                                    <h5
                                        className='text-gray-800 fw-bolder mb-n4 py-5 text-start ms-4 mb-5 pb-10'
                                    >
                                        Peak Pricing
                                    </h5>
                                    <>
                                        <div className='row mx-2'>
                                            <div className='col-5 text-start my-auto'>
                                                <span className='fw-bold'>Monthly Schedule</span>
                                            </div>
                                            <div className='col-7 text-end'>
                                                <span
                                                    style={{ width: '70px', height: '35px' }}
                                                    className='symbol symbol-35px bg-white text-center'
                                                >
                                                    <p className='fw-bold fs-6 text-primary mt-2'>
                                                        {data?.monthlySchedulePercentage}%
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row mx-2 mt-5'>
                                            <div className='col-5 text-start my-auto'>
                                                <span className='fw-bold'>Increase Rate</span>
                                            </div>
                                            <div className='col-7 text-end'>
                                                <span
                                                    style={{ width: '70px', height: '35px' }}
                                                    className='symbol symbol-35px bg-white text-center'
                                                >
                                                    <p className='fw-bold fs-6 text-primary mt-2'>
                                                        {data?.increaseRateBy} %
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row mx-2 mt-5'>
                                            <div className='col-5 text-start my-auto'>
                                                <span className='fw-bold'>VIC Excempt</span>
                                            </div>
                                            <div className='col-7 text-end'>
                                                <span
                                                    style={{ width: '70px', height: '35px' }}
                                                    className='symbol symbol-35px bg-white text-center'
                                                >
                                                    <p className='fw-bold fs-6 text-primary mt-2'>
                                                        {data?.isVicExempt ? 'Yes' : 'No'}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { OnetoOne }
