import {useMutation, useQuery, useQueryClient} from 'react-query'
import {
  GetMembersAppointmentsRequest,
  GetMembersListRequest,
  GetMembersProfileRequest,
  GetMembersTeamRequest,
  getMembersConnectionRequest,
  updateMemberStatusRequest,
} from '../../../../api/endpoints/member'
import {percentage} from '../../../../util'

// const queryKey = 'my-query-key'
type useProductsParams = Parameters<typeof GetMembersProfileRequest>

// export const useProviderProfile = (...params: useProductsParams) => {
//   return useQuery({
//     queryKey: 'memberProfile',
//     queryFn: () => GetMembersProfile(...params),
//     cacheTime: 0,
//     retry: false,
//     refetchOnMount: false,
//     retryOnMount: false,
//     select: (data) => {
//       const progressValues: Array<number> = [
//         data.services,
//         data.schedules,
//         data.isMedias,
//         data.credentials,
//         data.payments,
//       ]

//       const newData = { ...data, percentage: percentage(progressValues, progressValues.length) }

//       // console.log(newData)
//       return newData
//     },
//   })
// }

export const useMemberProfile = (...params: useProductsParams) => {
  return useQuery({
    queryKey: 'memberProfile',
    queryFn: () => GetMembersProfileRequest(...params),
    cacheTime: 0,
    retry: false,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,

    select: (data) => {
      const progressValues: Array<number> = [data.isMedias, data.credentials, data.payments]
      const newData = {...data, percentage: percentage(progressValues, progressValues.length)}
      return newData
    },
  })
}

export const useMemberList = (selectorFun: any) => {
  return useQuery({
    queryKey: ['memberList', selectorFun.params],
    queryFn: () => GetMembersListRequest(selectorFun.params),
    // enabled: false,
    staleTime: Infinity,
    select: selectorFun.func,
    refetchOnWindowFocus: false,
  })
}

export const useMemberStatusUpdate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateMemberStatusRequest,
    onSuccess: (data) => {
      // console.log('data', data)
      queryClient.invalidateQueries({queryKey: ['memberList']})
    },
  })
}

export const useMemberTeam = (id: string) => {
  return useQuery({
    queryKey: 'memberTeam',
    queryFn: () => GetMembersTeamRequest(id),
    select: (data) => {
      return data.map((element: any) => ({
        image: element.provider.profileImage,
        Name: `${element.provider.firstName} ${element.provider.lastName}`,
        parentService: element.rootServiceId.name,
        service: element.serviceId.name,
        id: element.provider.id,
        isVerified: element.provider.isMobileVerified,
      }))
    },
    cacheTime: 0,
  })
}

export const useMemberConnection = (id: string) => {
  return useQuery({
    queryKey: 'memberConnection',
    queryFn: () => getMembersConnectionRequest(id),
    cacheTime: 0,
  })
}

export const useMemberAppointment = (id: string, selectorFun: any) => {
  return useQuery({
    queryKey: 'memberAppointment',
    queryFn: () => GetMembersAppointmentsRequest(id),
    select: selectorFun,
    cacheTime: 0,
  })
}
