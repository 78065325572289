import { useParams } from 'react-router-dom'
import { UsersListFilter } from '../../../modules/apps/user-management/users-list/components/header/UsersListFilter'
import { useProviderMember } from '../hooks/useProviderQuery'
import ProfileCard from '../../../modules/common/ProfileCard'
import TableLoading from '../../../../_metronic/layout/components/Common/TableSkeletonLoading'
import { MDBDataTableV5 } from 'mdbreact'
import { useMemo } from 'react'
import { ViewIcon } from '../../../modules/common/TableIcons'
import { Chip } from '../../../modules/common/Chip'
import Verified from '../../../modules/common/Verified'
import { makeDate } from '../../../../util'

interface Member {
  id: number
  firstName: string
  lastName: string
  email: string
  username: string
  mobileNo: string
  profileImage: string | null
  bio: string | null
  address: string | null
  notes: any[]
  lastLogin: Date
  status: boolean
  isMobileVerified: 1 | 0
  isEmailVerified: 1 | 0
  isFav: 1 | 0
  totalConnections: 1 | 0
  createdAt: Date
}

type MemberRow = {
  id: number
  firstName: string | (JSX.Element[] | Element)[]
  lastName: string
  username: string
  phoneNumber: string
  lastLogin: any
  status: boolean
  isVerified: boolean
}

const MemberList = () => {
  const { Id } = useParams()

  const initTable = {
    columns: [
      {
        label: 'First name',
        field: 'firstName',
        sort: 'disabled',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Last name',
        field: 'lastName',
        width: 270,
      },
      {
        label: 'Username',
        field: 'username',
        width: 270,
      },
      {
        label: 'Phone Number',
        field: 'phoneNumber',
        width: 200,
      },
      {
        label: 'Last login at',
        field: 'lastLoginAt',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Status',
        field: 'status',
        width: 150,
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows: [],
  }

  const createRow = (row: MemberRow) => {
    return {
      firstName: [<Verified isVerified={row.isVerified} />, row.firstName],
      lastName: row.lastName,
      phoneNumber: row.phoneNumber,
      username: row.username,
      lastLoginAt: row.lastLogin ? [makeDate(row.lastLogin)] : 'N/a',
      status: [<Chip text={row.status} />],
      action: [<ViewIcon path={row.status ? `/member/${row.id}/profile` : ``} active={row.status} />],
    }
  }

  const listSelector = (res: any) => {
    let rows: never[] = []
    // console.log('for provider members res ', res)

    res?.forEach(({ client }: { client: Member }) => {
      if (client) {
        const row: MemberRow = {
          id: client?.id,
          firstName: client?.firstName,
          lastName: client?.lastName,
          phoneNumber: client?.mobileNo,
          username: client?.username,
          lastLogin: client?.lastLogin,
          status: Boolean(Number(client?.status)),
          isVerified: Boolean(Number(client?.isMobileVerified)),
        }
        rows.push(createRow(row) as never)
      }
    })
    let table = initTable
    table.rows = rows
    return table
  }
  const { isLoading, data } = useProviderMember(Id as string, listSelector)

  return (
    <>
      <div style={{ minHeight: '50vh' }} className='card mb-5 mb-xl-10'>
        <div className='row'>
          <div className='col-12 text-end'>
            <UsersListFilter />
          </div>
        </div>
        <div
          style={{
            overflow: 'auto',
            whiteSpace: 'nowrap',
          }}
          className='card mb-5 mb-xl-10 p-5'
        >
          {isLoading ? (
            <TableLoading />
          ) : (
            <MDBDataTableV5 hover order={['age', 'desc']} data={data} />
          )}
        </div>

        {/* {toggle[0].state && <DeleteModal onHide={setToggle} toggle={toggle[0]} />}
      {toggle[1].state && <PasswordModal onHide={setToggle} toggle={toggle[1]} />} */}
      </div>
    </>
  )
}

export default MemberList
// id=''
//                   avatar={item?.client?.profileImage}
//                   name={item?.client?.firstName}
//                   navLink={`/members/profile/${item?.client?.id}`}
//                   type='member'
