import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  addTransactionsRequest,
  deleteTransactionsRequest,
  getAllTransactionsRequest,
  getSingleTransactionsRequest,
  updateTransactionsRequest,
} from '../../../api/endpoints/transactions'
import {attachedPaginatedPayload, SHOW_ERROR, SHOW_SUCCESS} from '../../../util'
import {RootState} from '../../store'

interface transactionState {
  transactions: Array<any>
  transaction: any
  status: string
  error: string | null
  pagination: any
}

export const initialState: transactionState = {
  transactions: [],
  transaction: {},
  pagination: {},
  status: 'idle',
  error: null,
}

export const getTransactionsAction = createAsyncThunk('get/transactions', async (params: any) => {
  try {
    const res: any = await getAllTransactionsRequest(params)
    const {data, success, message} = res.data
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    console.log('get transactions error', error)
    return error
  }
})

export const getSingleTransactionsAction = createAsyncThunk(
  'get/single-transaction',
  async (id: string | number) => {
    try {
      const res: any = await getSingleTransactionsRequest(id)
      const {data, success} = res.data
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {
      return error
    }
  }
)

export const addTransactionAction = createAsyncThunk('post/transaction', async (rData: any) => {
  try {
    const res: any = await addTransactionsRequest(rData.data)
    const {data, success} = res.data
    if (success) {
      rData.SHOW_SUCCESS(true, 'Transaction added successfully')
      rData.navigate('/transaction-types')
    }
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

export const updateTransactionAction = createAsyncThunk(
  'update/transaction',
  async (rData: any) => {
    try {
      const res: any = await updateTransactionsRequest(rData.data, rData.data.id)
      const {data, success} = res.data
      if (success) {
        rData.SHOW_SUCCESS(true, 'Transaction updated successfully')
        rData.navigate('/transaction-types')
      }
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {
      return error
    }
  }
)

export const deleteTransactionAction = createAsyncThunk(
  'delete/transaction',
  async (params: any) => {
    try {
      const res: any = await deleteTransactionsRequest(params.id)
      // const {data, success} = res.data
      if (res?.data?.success) {
        SHOW_SUCCESS(true, 'Transaction deleted successfully')
        params.handleClose()
        return {
          id: params.id,
          data: res.data.data,
          success: res.data.success,
          code: 200,
        }
      } else {
        // console.log('data', res.response.data)
        SHOW_ERROR(true, res.response.data.message[0])
        params.handleClose()
        return {
          success: false,
          code: 400,
        }
      }
    } catch (error) {
      return error
    }
  }
)

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransactionsAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getTransactionsAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      state.transactions = action.payload.data
      state.pagination = attachedPaginatedPayload(action.payload.data)
    })
    builder.addCase(getTransactionsAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(getSingleTransactionsAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getSingleTransactionsAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      state.transaction = action.payload.data
    })
    builder.addCase(getSingleTransactionsAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(addTransactionAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(addTransactionAction.fulfilled, (state, action: any) => {
      state.status = 'success'
    })
    builder.addCase(addTransactionAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(updateTransactionAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(updateTransactionAction.fulfilled, (state, action: any) => {
      state.status = 'success'
    })
    builder.addCase(updateTransactionAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(deleteTransactionAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(deleteTransactionAction.fulfilled, (state, action: any) => {
      if (action.payload.code === 200) {
        state.status = 'success'
        state.transactions = state.transactions.filter((item: any) => item.id !== action.payload.id)
      } else {
        state.status = 'failed'
      }
    })
    builder.addCase(deleteTransactionAction.rejected, (state, action) => {
      state.status = 'failed'
    })
  },
})

export const transactionSelector = (state: RootState) => state.transactions.transactions
export const singleTransactionSelector = (state: RootState) => state.transactions.transaction
export const transactionStatusSelector = (state: RootState) => state.transactions.status

export default transactionSlice.reducer
