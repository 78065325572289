

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../util";

interface UserProfileHeaderTypes {
    data?: any;
    src?: string;
    name?: string;
}

const UserProfileHeader = ({
    data,
    src,
    name,
}: UserProfileHeaderTypes) => {
    let navigate = useNavigate();
    const [currentLocation, setCurrentLocation] = useState();

    const streetAddress = data?.uploadedBy === "P" ? data?.provider?.street +
        " " +
        data?.provider?.city +
        " " +
        data?.provider?.state : data?.client?.street +
        " " +
        data?.client?.city +
        " " +
    data?.client?.state;

    useEffect(() => {
        if (navigator.geolocation) {
            fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data?.latitude
                },${data?.longitude}&key=${"AIzaSyBZpthDFZSDsycPrfs6XbJBBbu5Z5e1FmE"}`
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === "OK") {
                        const results = data.results;
                        if (results.length > 0) {
                            const address = results[0].formatted_address;
                            setCurrentLocation(address);
                        } else {
                            console.log("No current address found.");
                        }
                    } else {
                        console.warn("Geocoding request failed. Status: " + data.status);
                    }
                })
                .catch((error) => {
                    console.warn("Error occurred while geocoding: " + error);
                });
        } else {
            console.log("access denied");
        }
    }, []);

    return (
        <div className="mt-3">
            <div
                className="d-flex justify-content-center"
            // onClick={() => {
            //     if (data?.isProvider) {
            //         if (data?.profile?.id) {
            //             navigate(
            //                 `/connections/profile/${data?.profile?.id}?type=provider`
            //             );
            //         }
            //     } else {
            //         navigate(`/connections/profile/${data?.profile?.id}?type=member`);
            //     }
            // }}
            >
                <img
                    style={{ objectFit: "contain" }}
                    height={40}
                    width={40}
                    className="me-2 rounded-circle border border-primary"
                    src={src ? src : "/images/avatar2.png"}
                    alt="no icon"
                    loading="lazy"
                />
                <div className="">
                    <p className={`mb-0 text-white`} style={{ fontSize: "14px" }}>
                        {name}
                    </p>
                    <p
                        style={{ fontSize: "13px", minHeight: "30px" }}
                        className="mb-0 text-white"
                    >
                        {currentLocation
                            ? currentLocation
                            : data?.location
                                ? data?.location
                                : streetAddress}
                        <div>{formatDate(data?.createdAt)}</div>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UserProfileHeader;
