export const convertTime = (time: string): { time: string; Meridiem: string } => {
  if (time?.length > 0) {
    const poc = time?.split(' ')
    if (poc?.length > 0) {
      return { time: poc[0], Meridiem: poc[1] }
    }
  }
  return { time: 'TT:TT', Meridiem: 'MM' }
}

export function makeDate(rawDate: any) {
  const date = new Date(rawDate)
  const nweDate = new Intl.DateTimeFormat(undefined).format(date)

  return nweDate
}

export const percentage = (total: Array<number>, percent: number) => {
  const temp = total.reduce((sum: number, record: number) => sum + record)
  return (temp / percent) * 100
}

export const formatLocalTime = (timeString: any) => {
  const [hours, minutes, seconds] = timeString.split(':')
  const date = new Date()

  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(seconds)

  const localTimeString = date.toLocaleTimeString([], { timeStyle: 'short' })

  return localTimeString
}

export const snake_to_title = (key: string) => {
  // Example: Convert snake_case to Title Case
  return key
    ?.split('_')
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function array_to_CSV(arry: Array<any>) {
  return arry?.join()
}

// const routes: string[] = [
//     'provider/profile/:Id',
//     'provider/members/:Id',
//     'provider/services/:Id',
//     'provider/appointment/:Id',
//     'provider/schedule/:Id',
//     'provider/media/:Id',
//     'provider/credentials/:Id',
//     'provider/payments/:Id',
// ];

// // URL to check
// const url: string = '/provider/profile/12345';

// for (const route of routes) {
//     // Escape special characters and replace ':Id' with a regex pattern
//     const pattern = new RegExp(
//         route.replace(/:[^/]+/g, '\\d+').replace(/\//g, '\\/')
//     );

// if (pattern.test(url)) {
//     console.log(`Active Route: ${route}`);
//     break;
// }
// }

export function matchUrl(path: string, url: string): boolean {
  if (url === path) return true
  return false
}

export function matchUrlWithRegex(path: string, url: string): boolean {
  const pattern = new RegExp(path.replace(/:[^/]+/g, '\\d+').replace(/\//g, '\\/'))

  if (pattern.test(url)) {
    return true
  }
  return false
}

export function checkAppointmentStatus(status: any, isPaid: number, isReservationFee: number) {
  let statusToShow
  let tag
  if (status === 'pending' || status === 'rescheduleByMember') {
    statusToShow = 'Pending Pro Approval'
    tag = 'tag-success'
  }

  if (status === 'approveByProvider') {
    statusToShow = 'Confirm By Provider'
    tag = 'tag-success'
  }
  if (status === 'completed' && (isPaid === 2 || isPaid === 0)) {
    statusToShow = 'Completed And Unpaid'
    tag = 'tag-brown'
  }

  if (status === 'completed' && isPaid === 1) {
    statusToShow = 'Completed And Paid'
    tag = 'tag-success'
  }
  if (status === 'PaidByCash') {
    statusToShow = 'Paid By Cash'
    tag = 'tag-success'
  }
  if (status === 'declineByProvider') {
    statusToShow = 'Decline By Provider'
    tag = 'tag-danger'
  }

  if (status === 'cancelledByMember') {
    statusToShow = 'Cancel By Member'
    tag = 'tag-purple'
  }

  if (status === 'rescheduleByProvider') {
    statusToShow = 'Reschedule By Provider'
    tag = 'tag-brown'
  }

  if (status === 'dismissedByMember') {
    statusToShow = 'Dismissed By Member'
    tag = 'tag-purple'
  }

  if (
    isReservationFee === 1 &&
    isPaid === 0 &&
    status !== 'cancelledByMember' &&
    status !== 'declineByProvider'
  ) {
    statusToShow = 'Reservation Fee Unpaid'
    tag = 'tag-warning'
  }

  if (status === 'expiredTimeReserve') {
    statusToShow = 'Expired Time Reserve'
    tag = 'tag-warning'
  }

  if (status === 'underTimeReserve') {
    statusToShow = 'Under Time Reserve'
    tag = 'tag-warning'
  }

  return { statusToShow, tag }
}

export function extractValues(notifications: any) {
  const extractedValues: any = []

  const uniqueKeys = new Set()

  notifications?.forEach((notification: any) => {
    const key = Object.keys(notification)[0]
    const { delay, channels, message, available_variables, is_active } = notification[key]

    if (!uniqueKeys.has(key)) {
      uniqueKeys.add(key)

      extractedValues.push({
        key,
        delay,
        channels,
        message,
        available_variables,
        is_active,
      })
    }
  })

  return extractedValues
}

export function extractSMSValues(notifications: any) {
  const extractedValues: any = []

  // Iterate over the keys of the notifications object
  Object.keys(notifications).forEach((key) => {
    const { title, message, available_variables, is_active } = notifications[key]

    extractedValues.push({
      key,
      title,
      message,
      available_variables,
      is_active,
    })
  })

  return extractedValues
}

export function attachedPaginatedPayload(dataList: any) {
  const paginationKeys = [
    'currentPage',
    'perPage',
    'total',
    'lastPage',
    'firstPageUrl',
    'lastPageUrl',
    'nextPageUrl',
    'previousPageUrl',
  ]
  // get the pagination object from the response data
  return Object.keys(dataList)
    .filter((key) => paginationKeys.includes(key))
    .reduce((obj: any, key) => {
      obj[key] = dataList[key]
      return obj
    }, {})
}

export function formatDate(input: any) {
  const currentDate: any = new Date();
  let inputDate: any;

  if (typeof input === "string") {
    inputDate = new Date(input);
  } else if (input instanceof Date) {
    inputDate = input;
  } else {
    throw new Error(
      "Invalid input. Please provide a valid Date object or a date string."
    );
  }

  const timeDifference = currentDate - inputDate;
  const oneDay = 24 * 60 * 60 * 1000; // 1 day in milliseconds

  if (timeDifference < oneDay) {
    return "Today";
  } else if (timeDifference < 2 * oneDay) {
    return "Yesterday";
  } else if (
    inputDate.getDate() >= currentDate.getDate() - currentDate.getDay() &&
    inputDate.getDate() <= currentDate.getDate() + (6 - currentDate.getDay())
  ) {
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return dayNames[inputDate.getDay()] + " " + inputDate.toLocaleDateString();
  } else {
    return inputDate.toLocaleDateString();
  }
}
