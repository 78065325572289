import client from '../client/client'

const getProvidersListRequest = async (params: any) => {
  try {
    const {data} = await client.get('api/v1/admin/provider/listing', {
      params: {
        ...params,
        sort: 'id|desc',
      },
    })
    return data.data
  } catch (error) {
    return error
  }
}

const getProviderProfileRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/provider/${id}`)
    return data.data
  } catch (error) {
    return error
  }
}

const getProvidersMembersRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/provider/${id}/members`)
    return data.data
  } catch (error) {
    return error
  }
}

// const id = 431

const getProviderServicesRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/provider/${id}/services`)
    return data.data
  } catch (error) {
    return error
  }
}
const getProvider2ndServicesRequest = async (Data: any) => {
  try {
    const {data}: any = await client.get('api/v1/admin/provider/services', {
      params: {
        providerId: Data.proId,
        level: Data.lvl,
        serviceId: Data.serviceId,
      },
    })
    return data.data
  } catch (error) {
    return error
  }
}

const getProviderAppointmentsRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/provider/${id}/appointments`)
    return data.data
  } catch (error) {
    return error
  }
}

const getProviderCredentialsRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/provider/${id}/credentials`, {})
    return data.data
  } catch (error) {
    return error
  }
}

const getProviderScheduleRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/provider/${id}/schedule`)
    return data.data
  } catch (error) {
    return error
  }
}

const getProviderVacationRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/provider/${id}/vacations`)
    return data.data
  } catch (error) {
    return error
  }
}

const getProviderMediaRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/provider/${id}/media`)
    return data.data
  } catch (error) {
    return error
  }
}

const deleteProvider = async (id: any) => {
  try {
    return await client.delete(`api/v1/admin/provider/delete/${id}`, {})
  } catch (error) {
    return error
  }
}

const updateProviderStatusRequest = async (data: any) => {
  try {
    return await client.put(`api/v1/admin/provider/update-status`, data)
  } catch (error) {
    return error
  }
}

const updateProviderPasswordRequest = async (data: any) => {
  try {
    return await client.post(`api/v1/admin/provider/reset-password`, data)
  } catch (error) {
    return error
  }
}

export {
  getProvidersListRequest,
  getProvidersMembersRequest,
  getProviderServicesRequest,
  getProviderAppointmentsRequest,
  getProviderCredentialsRequest,
  getProviderScheduleRequest,
  getProviderMediaRequest,
  getProviderProfileRequest,
  deleteProvider,
  getProvider2ndServicesRequest,
  getProviderVacationRequest,
  updateProviderStatusRequest,
  updateProviderPasswordRequest,
}
