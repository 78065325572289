import {useQuery} from 'react-query'
import {
  GetAppointmentHistoryRequest,
  GetAppointmentsListRequest,
  fetchSingleAppointmentRequest,
} from '../../../../api/endpoints/appointment'

export const useAppointmentList = (selectorFun: any) => {
  return useQuery({
    queryKey: ['appointmentList', selectorFun.params],
    queryFn: () => GetAppointmentsListRequest(selectorFun.params),
    select: selectorFun.func,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  })
}

export const useGetAppointmentHistory = (selectorFun: any) => {
  return useQuery({
    queryKey: ['appointmentHistory'],
    queryFn: () => GetAppointmentHistoryRequest(selectorFun.params),
    select: selectorFun.func,
    cacheTime: 0,
  })
}

export const useAppointmentDetail = (id: string) => {
  return useQuery({
    queryKey: 'appointmentDetail',
    queryFn: () => fetchSingleAppointmentRequest(id),
    cacheTime: 0,
  })
}
