import {MDBDataTableV5} from 'mdbreact'
import TableLoading from '../../../_metronic/layout/components/Common/TableSkeletonLoading'
import {formatLocalTime} from '../../../util/helper'
import {DeleteIcon, LockIcon, ViewIcon} from '../../modules/common/TableIcons'
import {Link, useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useAppDispatch} from '../../store'
import {useSelector} from 'react-redux'
import PasswordResetModal from '../profile/components/PasswordResetModal'
import {ToggleState} from '../Provider/modals/type'
import {Box, Tab, Tabs} from '@mui/material'
import {
  getTransactionsAction,
  transactionSelector,
  transactionStatusSelector,
} from '../../reducers/transactionsReducer'
import DeleteModal from './modals/DeleteModal'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  )
}

const TransactionsList = () => {
  const dispatch: any = useAppDispatch()

  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const transactionList = useSelector(transactionSelector)
  const status = useSelector(transactionStatusSelector)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const allowDelete: boolean = queryParams.get('super') === 'true'

  const [type, setType] = useState('r')
  const [userData, setUserData] = useState<any>()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [toggle, setToggle] = useState<ToggleState[]>([{state: false, name: 'd-modal'}])

  const [initTable, setInitTable] = useState({
    columns: [
      // {
      //   label: 'Id',
      //   field: 'id',
      //   sort: 'disabled',
      //   width: 150,
      // },
      // {
      //   label: 'Type',
      //   field: 'type',
      //   sort: 'disabled',
      //   width: 150,
      // },
      {
        label: 'Description',
        field: 'description',
        width: 270,
      },
      {
        label: 'Status',
        field: 'isActive',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows: [],
  })

  useEffect(() => {
    dispatch(
      getTransactionsAction({
        type: type,
      })
    )
  }, [type])

  function handleOpenModal(modal: string, itemId: string) {
    setToggle((prev) => {
      let modalState = prev.map((curState) => {
        if (curState.name === modal) {
          curState.state = true
          curState.id = itemId
        }
        return curState
      })
      return modalState
    })
  }

  useEffect(() => {
    if (transactionList) {
      const list: any = transactionList?.map((item: any) => {
        return {
          // id: item.id,
          // type: item.type,
          description: item.description,
          isActive: item.isActive === 1 ? 'Active' : 'Inactive',
          action: [
            <ViewIcon active={true} path={`/transaction-types/${item.id}`} />,
            <DeleteIcon byClick={() => handleOpenModal('d-modal', item.id)} path='#' />,
          ],
        }
      })
      setInitTable((prevTable) => {
        return {
          ...prevTable,
          rows: list,
        }
      })
    }
  }, [transactionList])

  return (
    <>
      <div className='row mb-5'>
        <div className='col-6'>
          <h2 className='py-3 mb-2'>Transaction Types</h2>
        </div>
        <div className='col-6 align-self-center text-end'>
          <Link to={`/transaction-types/create`}>
            <button type='button' className='btn btn-primary '>
              Create
            </button>
          </Link>
        </div>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab
            label='Refund'
            id={`simple-tab-${0}`}
            aria-controls={`simple-tabpanel-${0}`}
            onClick={() => setType('r')}
          />
          <Tab
            label='Tax'
            id={`simple-tab-${1}`}
            aria-controls={`simple-tabpanel-${1}`}
            onClick={() => setType('t')}
          />
          <Tab
            label='Discount'
            id={`simple-tab-${2}`}
            aria-controls={`simple-tabpanel-${2}`}
            onClick={() => setType('d')}
          />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <div
            style={{
              overflow: 'auto',
              whiteSpace: 'nowrap',
            }}
            className='card mb-5 mb-xl-10 p-5'
          >
            {status === 'loading' ? (
              <TableLoading />
            ) : (
              <MDBDataTableV5 hover order={['age', 'desc']} data={initTable} />
            )}
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div
            style={{
              overflow: 'auto',
              whiteSpace: 'nowrap',
            }}
            className='card mb-5 mb-xl-10 p-5'
          >
            {status === 'loading' ? (
              <TableLoading />
            ) : (
              <MDBDataTableV5 hover order={['age', 'desc']} data={initTable} />
            )}
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <div
            style={{
              overflow: 'auto',
              whiteSpace: 'nowrap',
            }}
            className='card mb-5 mb-xl-10 p-5'
          >
            {status === 'loading' ? (
              <TableLoading />
            ) : (
              <MDBDataTableV5 hover order={['age', 'desc']} data={initTable} />
            )}
          </div>
        </CustomTabPanel>
      </div>

      {toggle[0].state && <DeleteModal onHide={setToggle} toggle={toggle[0]} />}
      <PasswordResetModal open={open} handleClose={handleClose} userData={userData} />
    </>
  )
}

export {TransactionsList}
