/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import {FC} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import MediaCard from './components/MediaCard'
import {getExplores} from '../../../api/endpoints/explore'
import InfiniteScroll from 'react-infinite-scroll-component'
import UserProfileHeader from './components/UserProfileHeader'
import UserProfileFooter from './components/UserProfileFooter'
import Lightbox from 'yet-another-react-lightbox'
import Video from 'yet-another-react-lightbox/plugins/video'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import {MediaFilters} from './components/MediaFilters'
import {
  addMediaToState,
  aggregateFilter,
  getAllMediaAction,
  mediaFilterSelector,
  mediaPaginationSelector,
  mediasSelector,
  mediaStatusSelector,
} from '../../reducers/exploreReducer'
import {useDispatch, useSelector} from 'react-redux'
import client from '../../../api'

type Props = {}

const Explore: FC<Props> = () => {
  const dispatch: any = useDispatch()

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [item, setItem] = React.useState<any>(0)

  const media: any = useSelector(mediasSelector)
  const mediaStatus = useSelector(mediaStatusSelector)
  const pagination = useSelector(mediaPaginationSelector)
  const filtersData = useSelector(mediaFilterSelector)

  React.useEffect(() => {
    dispatch(
      getAllMediaAction({
        page: 1,
        perPage: 10,
        ...filtersData,
      })
    )
    return () => {
      // setMediaList([])
    }
  }, [filtersData])

  const getMedia = async () => {
    const params = {...filtersData, perPage: 10}
    const res: any = await client.get(pagination?.nextPageUrl, {
      params: params,
    })
    // console.log(res)

    if (res.data.success) {
      dispatch(addMediaToState(res.data))
    }
  }

  const handleChange = (e: any) => {
    if (e.target.value === '') {
      dispatch(aggregateFilter({key: 'query', value: ''}))
    } else {
      // setSearching(true);
      if (e.target.value.length > 2) {
        dispatch(aggregateFilter({key: 'query', value: e.target.value.toUpperCase()}))
      }
    }
    // console.log(search);
  }

  return (
    <>
      <div className='row mb-5 mt-n5 pt-n5'>
        <div className='col-6'>
          <h2 className='py-3 mb-2'>Feed</h2>
        </div>
        <div className='col-6 align-self-center text-end'>
          <MediaFilters filtersData={filtersData} />
        </div>
      </div>
      <div className='card mb-5 mb-xl-10 pt-4'>
        {/* search section */}
        <div className='d-flex align-items-center justify-content-end my-3 px-4'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute me-5'
          />
          <input
            onChange={handleChange}
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search media'
          />
        </div>
        {media?.length === 0 ? (
          <div
            style={{minHeight: '50vh'}}
            className={`card mb-5 mb-xl-10 py-8 d-flex justify-content-center`}
          >
            <div className='d-flex justify-content-center my-5'>
              <div className='spinner-grow text-dark' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={media?.length} //This is important field to render the next data
            next={() => getMedia()}
            hasMore={pagination?.nextPageUrl}
            loader={
              <div className='d-flex justify-content-center my-5'>
                <div className='spinner-grow text-dark' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            }
            endMessage={
              <p style={{textAlign: 'center'}}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div className='row w-100 m-auto align-items-center mt-4'>
              {media?.map((data: any, idx: number) => (
                <MediaCard
                  key={idx}
                  data={data}
                  index={idx}
                  handleOpen={handleOpen}
                  setItem={setItem}
                />
              ))}
              <Lightbox
                styles={{
                  container: {
                    margin: 'auto',
                    backgroundColor: 'rgba(0, 0, 0, .8)',
                    zIndex: '100',
                  },
                }}
                open={open}
                index={Number(item)}
                close={() => setOpen(false)}
                // @ts-ignore
                slides={[
                  ...media?.map((imageData: any) => {
                    if (imageData?.type.includes('image')) {
                      return {
                        src: imageData?.url,
                        title: (
                          <UserProfileHeader
                            data={imageData}
                            src={
                              imageData?.uploadedBy === 'P'
                                ? imageData?.provider?.profile_image
                                : imageData?.client?.profile_image
                            }
                            name={
                              imageData?.uploadedBy === 'P'
                                ? imageData?.provider?.first_name +
                                  ' ' +
                                  imageData?.provider?.last_name
                                : imageData?.client?.first_name + ' ' + imageData?.client?.last_name
                            }
                          />
                        ),
                        description: <UserProfileFooter item={imageData} />,
                      }
                    } else {
                      return {
                        type: 'video',
                        width: 1050,
                        height: 550,
                        poster: imageData?.thumbnail,
                        title: (
                          <UserProfileHeader
                            data={imageData}
                            src={
                              imageData?.uploadedBy === 'P'
                                ? imageData?.provider?.profile_image
                                : imageData?.client?.profile_image
                            }
                            name={
                              imageData?.uploadedBy === 'P'
                                ? imageData?.provider?.first_name +
                                  ' ' +
                                  imageData?.provider?.last_name
                                : imageData?.client?.first_name + ' ' + imageData?.client?.last_name
                            }
                          />
                        ),
                        description: <UserProfileFooter item={imageData} />,
                        sources: [
                          {
                            src: imageData?.url,
                            type: `${imageData?.type}/mp4`,
                          },
                        ],
                      }
                    }
                  }),
                ]}
                render={
                  {
                    // buttonPrev: () => null,
                    // buttonNext: () => null,
                  }
                }
                video={{
                  autoPlay: true,
                  controls: true,
                }}
                plugins={[Captions, Video]}
              />
            </div>
          </InfiniteScroll>
        )}
      </div>
    </>
  )
}

export {Explore}
