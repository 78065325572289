import client from '../client/client'

const getExplores = async (params: any) => {
  try {
    return await client.get(`api/v1/admin/explores`, {
      params: params,
    })
  } catch (error) {
    return error
  }
}

const deleteExplore = async (Id: any) => {
  try {
    return await client.delete(`api/v1/admin/media/delete/${Id}`)
  } catch (error) {
    return error
  }
}
export {getExplores, deleteExplore}
