/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useProviderSchedule, useProviderVacation } from '../../hooks/useProviderQuery'
import { DAY } from '../../../../../config/constant'
import { AiOutlineFileDone } from 'react-icons/ai'
import { Box, Tab, Tabs } from '@mui/material'
import { TabPanel, a11yProps } from '../../../../modules/common/Tabs/Config'
import { OnetoOne } from './components/OnetoOne'
import { MultitoMulti } from './components/multi/MultitoMulti'
import { Vacation } from './components/Vacation'

type Profile = {
  // scheduleDays: string
  contactPhone: string
  businessName: string
  lastLoginat: string
  Address: string
  bio: string
  lattitude: string
  longitude: string
  referralCode: string
  verificationCodeSentat: string
  createdAt: string
  profileImg: string
}

const ProvidersSchedule: FC = () => {
  const [providerDataMulti, setProviderDataMulti] = useState<any>([])
  const [openedTab, setOpenedTab] = useState<number>(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setOpenedTab(newValue)
  }
  const [providerDataOneToOne, setProviderDataOneToOne] = useState<any>([])
  const { Id } = useParams()

  const { data, isSuccess } = useProviderSchedule(Id as string)
  const { data: vacationData } = useProviderVacation(Id as string)
  // console.log(data, success, message)
  useEffect(() => {
    if (isSuccess) {
      let selectedArry = data?.find((pD: any) => pD.type === 'single-to-single')
      let newDAY = [...DAY]

      newDAY.forEach((d: any) => {
        selectedArry?.days?.forEach(({ scheduleDays }: any) => {
          if (scheduleDays === d.name) {
            d.hasIt = true
          }
        })
      })

      setProviderDataOneToOne({ ...selectedArry, days: newDAY })
      setProviderDataMulti(data?.filter((pD: any) => pD.type === 'multi-to-multi'))
    }
  }, [isSuccess, data])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body px-9 py-2 w-100'>
          <Box sx={{ width: '100%' }}>
            <Tabs
              className='row w-100 m-auto'
              textColor='secondary'
              indicatorColor='secondary'
              value={openedTab}
              onChange={handleTabChange}
              aria-label='basic tabs example'
            >
              <Tab
                style={{ fontSize: '13px', fontWeight: 'bold', width: '33.3%' }}
                className='col-6'
                icon={
                  providerDataOneToOne?.isActive ?
                    <AiOutlineFileDone size={'2rem'} />
                    : <></>
                }
                iconPosition='end'
                label='One to One'
                {...a11yProps(0)}
              />
              <Tab
                style={{ fontSize: '13px', fontWeight: 'bold', width: '33.3%' }}
                className='col-6'
                icon={
                  providerDataMulti[0]?.isActive ?
                    <AiOutlineFileDone size={'2rem'} />
                    : <></>
                }
                iconPosition='end'
                label='Multi to Multi'
                {...a11yProps(1)}
              />
              <Tab
                style={{ fontSize: '13px', fontWeight: 'bold', width: '33.3%' }}
                className='col-6'
                icon={''}
                iconPosition='start'
                label='Vacation'
                {...a11yProps(2)}
              />
            </Tabs>
            <TabPanel value={openedTab} index={0}>
              <OnetoOne data={providerDataOneToOne} />
            </TabPanel>
            <TabPanel value={openedTab} index={1}>
              <MultitoMulti data={providerDataMulti} />
            </TabPanel>
            <TabPanel value={openedTab} index={2}>
              <Vacation data={vacationData} />
            </TabPanel>
          </Box>
        </div>
      </div>
    </>
  )
}

export default ProvidersSchedule
