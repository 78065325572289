import React from 'react'
import { useParams } from 'react-router-dom'
import { useProviderSecondLvlService } from '../../../hooks/useProviderQuery'

const SecondLvlService = ({ setLvl }: any) => {
    const { Id } = useParams()

    const Data = {
        proId: Id,
        lvl: 2,
    }
    const { isLoading, data } = useProviderSecondLvlService(Data)
    return (
        <div style={{ minHeight: '50vh' }} className={`card mb-5 mb-xl-10 ${data?.length === 0 && "d-flex justify-content-center"}`}>
            <div className='row mt-5'>
                {isLoading ? (
                    <div className='d-flex justify-content-center my-5'>
                        <div className='spinner-grow text-dark' role='status'>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                ) : (
                    data?.length === 0 ? (
                        <div className='text-center'>
                            <h3 className='text-muted'>No matching records found :(</h3>
                        </div>
                    )
                        :
                        (data?.map((data: any, idx: number) => (
                            <div key={idx} className='col-md-3'>
                                <div style={{ backgroundColor: '#fff' }} className={`my-2 text-center rounded p-4`}>
                                    <div className='text-start my-auto'>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                            <img className='rounded-circle' src={data?.icon} alt='icon' height={50} />
                                            <h4 className='text-primary text-center mt-3 pointer text-capitalize'>
                                                {data?.name}
                                            </h4>
                                        </div>
                                    </div>
                                    <div
                                        className={`btn btn-sm py-1 px-15 btn-primary`}
                                        onClick={() => {
                                            console.log('logooooo')
                                            setLvl({
                                                lvl: 3,
                                                serviceId: data?.id,
                                            })
                                        }}
                                    >
                                        Child Services
                                    </div>
                                </div>
                            </div>
                        )))
                )}
            </div>
        </div>
    )
}

export default SecondLvlService
