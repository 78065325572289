import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../_metronic/helpers'
import {appointmentStatus} from '../../../../config/constant'
import {useLocation} from 'react-router-dom'

const PaymentSummaryFilters = ({memberData, providerData, setSearchParams, setPage}: any) => {
  const [filterData, setFilterData] = useState<any>({
    providerId: '',
    status: '',
    fromDate: '',
    toDate: '',
    clientId: '',
  })

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setFilterData({
      providerId: '',
      status: '',
      fromDate: '',
      toDate: '',
      clientId: '',
    })
    setSearchParams()
    setPage(1)
  }

  const handleApplyFilter = () => {
    // Filter out empty values from filterData
    const filteredParams = Object.fromEntries(
      Object.entries(filterData).filter(([_, value]) => value !== '')
    )

    setSearchParams(filteredParams)
    setPage(1)
  }

  return (
    <>
      <button
        // disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3 my-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown w-250px w-md-300px h-50 overflow-auto'
        data-kt-menu='true'
      >
        <div className='px-7 py-5 text-center'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-5 text-start'>
            <label className='form-label fs-6 fw-bold'>Provider:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='provider'
              data-hide-search='true'
              onChange={(e) =>
                setFilterData({
                  ...filterData,
                  providerId: e.target.value,
                })
              }
              value={filterData.providerId}
            >
              <option value=''>Select provider</option>
              {providerData?.map(
                (element: any) =>
                  element?.isVerified && (
                    <option value={element.id}>{element.firstName + ' ' + element.lastName}</option>
                  )
              )}
            </select>
          </div>

          <div className='mb-5 text-start'>
            <label className='form-label fs-6 fw-bold'>Client:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='client'
              data-hide-search='true'
              onChange={(e) =>
                setFilterData({
                  ...filterData,
                  clientId: e.target.value,
                })
              }
              value={filterData.clientId}
            >
              <option value=''>Select client</option>
              {memberData?.map(
                (element: any) =>
                  element?.isVerified && (
                    <option value={element.id}>{element.firstName + ' ' + element.lastName}</option>
                  )
              )}
            </select>
          </div>

          <div className='mb-5 text-start'>
            <label className='form-label fs-6 fw-bold'>Status:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='status'
              data-hide-search='true'
              onChange={(e) =>
                setFilterData({
                  ...filterData,
                  status: e.target.value,
                })
              }
              value={filterData.status}
            >
              <option value=''>Select status</option>
              <option value='pending'>Pending</option>
              <option value='completed'>Completed</option>
              <option value='refunded'>Refunded</option>
            </select>
          </div>

          <div className='mb-5 text-start'>
            <div className='d-flex flex-column'>
              <div>
                <label htmlFor='' className='form-label fs-6 fw-bold'>
                  From:
                </label>
                <input
                  type='date'
                  name=''
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      fromDate: e.target.value,
                    })
                  }
                  value={filterData.fromDate}
                  className='form-control form-control-solid fw-bolder'
                  id=''
                />
              </div>
              <div>
                <label htmlFor='' className='form-label fs-6 fw-bold'>
                  To:
                </label>
                <input
                  type='date'
                  name=''
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      toDate: e.target.value,
                    })
                  }
                  value={filterData.toDate}
                  min={filterData.fromDate}
                  className='form-control form-control-solid fw-bolder'
                  id=''
                />
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              //   disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              //   disabled={isLoading}
              type='button'
              onClick={handleApplyFilter}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {PaymentSummaryFilters}
