import React, {useEffect} from 'react'
import {MDBDataTableV5} from 'mdbreact'
import {Link, useParams} from 'react-router-dom'
import {useProviderThirdLvlService} from '../../../hooks/useProviderQuery'
import {UsersListFilter} from '../../../../../modules/apps/user-management/users-list/components/header/UsersListFilter'
import TableLoading from '../../../../../../_metronic/layout/components/Common/TableSkeletonLoading'

type ProviderServicesRow = {
  name: string
  // parent: string
  // grandParent: string
  description: string
  price: string
  duration: string
  serviceIcon: string
}

type CurrentServiceType = {
  lvl: number
  serviceId: string | null
}

type ProviderServices = {
  currentService: CurrentServiceType
}

const ProvidersServiceList = ({currentService}: ProviderServices) => {
  const {Id} = useParams()

  const initTable = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'disabled',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Price',
        field: 'price',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Duration',
        field: 'duration',
        width: 150,
      },
    ],
    rows: [],
  }

  const rowItem = (item: ProviderServicesRow) => {
    return {
      name: [
        <Link to='#' data-bs-toggle='tooltip' data-bs-placement='top' title='Facebook'>
          {/* <BsFacebook className='me-1 mb-1' color='#4267B2' size={'1.1rem'} />{' '} */}
          <img className='me-2' src={item.serviceIcon} alt='' height={'30px'} width={'30px'} />
        </Link>,
        item.name,
      ],
      price: item.price ? <span>${item.price}</span> : null,
      duration: [[item.duration, <span className='ms-1'>minutes</span>]],
    }
  }

  const listSelector = (res: any) => {
    var rows: never[] = []

    res?.forEach((ser: any, index: number) => {
      const item: ProviderServicesRow = {
        name: ser?.name,
        description: ser?.description,
        price: ser?.price,
        duration: ser?.duration,
        serviceIcon: ser?.service?.icon,
      }
      rows.push(rowItem(item) as never)
    })

    var table = initTable
    table.rows = rows
    return table
  }

  const Data = {
    proId: Id,
    serviceId: currentService?.serviceId,
    lvl: currentService?.lvl,
  }
  const {isLoading, data} = useProviderThirdLvlService(Data, listSelector)

  return (
    <>
      <div style={{minHeight: '50vh'}} className={`card mb-5 mb-xl-10`}>
        <div className='row'>
          <div className='col-12 text-end'>{/* <UsersListFilter /> */}</div>
        </div>

        <div
          style={{
            overflow: 'auto',
            whiteSpace: 'nowrap',
          }}
          className='card mb-5 mb-xl-10 p-5'
        >
          {isLoading ? (
            <TableLoading />
          ) : (
            <MDBDataTableV5 hover order={['age', 'desc']} data={data} />
          )}
        </div>
      </div>
    </>
  )
}

export default ProvidersServiceList
