import React, {useState} from 'react'
import {MemberAppointment, MemberConnection, MemberProfile, MemberTeam, MemberTopHeader} from '.'
import {useMemberProfile} from '../hooks/useMemberQuery'
import {useParams} from 'react-router-dom'

const NewMemberProfile = () => {
  const {Id} = useParams()
  const {isLoading, data} = useMemberProfile(Id)
  const [tab, setTab] = useState<string>('profile')

  return (
    <div>
      <MemberTopHeader tab={tab} setTab={setTab} data={data} />
      {tab === 'profile' ? (
        <MemberProfile data={data} />
      ) : tab === 'team' ? (
        <MemberTeam Id={Id} />
      ) : tab === 'appointment' ? (
        <MemberAppointment Id={Id} />
      ) : tab === 'connection' ? (
        <MemberConnection Id={Id} />
      ) : null}
    </div>
  )
}

export default NewMemberProfile
