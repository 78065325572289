import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  addFaqRequest,
  deleteFaqRequest,
  getAllFaqRequest,
  getSingleFaqRequest,
  updateFaqRequest,
} from '../../../api/endpoints/faq'
import {RootState} from '../../store'
import {SHOW_SUCCESS, attachedPaginatedPayload} from '../../../util'

interface faqState {
  faqs: Array<any>
  faq: any
  pagination: any
  status: string
  error: string | null
}

export const initialState: faqState = {
  faqs: [],
  faq: {},
  pagination: {},
  status: 'idle',
  error: null,
}

export const getAllFaqAction = createAsyncThunk('get/all-faq', async (params:any) => {
  try {
    const res: any = await getAllFaqRequest(params)
    const {data, success} = res.data
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

export const getSingleFaqAction = createAsyncThunk(
  'get/single-faq',
  async (id: string | number) => {
    try {
      const res: any = await getSingleFaqRequest(id)
      const {data, success} = res.data
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {
      return error
    }
  }
)

export const addFaqAction = createAsyncThunk('add/faq', async (rData: any) => {
  try {
    const res: any = await addFaqRequest(rData.data)
    const {data, success} = res.data
    if (success) {
      rData.editor.setData('<p></p>')
      rData.reset({
        answer: '',
        question: '',
      })
      SHOW_SUCCESS(true, 'FAQ added successfully')
      rData.navigate('/faq')
    }
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

export const updateFaqAction = createAsyncThunk('update/faq', async (rData: any) => {
  try {
    const res: any = await updateFaqRequest(rData)
    const {data, success} = res.data
    if (success) {
      SHOW_SUCCESS(true, 'FAQ updated successfully')
    }
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

export const deleteFaqAction = createAsyncThunk('delete/faq', async (id: number | string) => {
  try {
    const res: any = await deleteFaqRequest(id)
    const {data, success} = res.data
    return {
      id,
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllFaqAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getAllFaqAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      //   console.log('data', action.payload)

      state.faqs = action.payload.data.faqs
      state.pagination = attachedPaginatedPayload(action.payload.data)
    })
    builder.addCase(getAllFaqAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(getSingleFaqAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getSingleFaqAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      state.faq = action.payload.data
    })
    builder.addCase(getSingleFaqAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(addFaqAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(addFaqAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      state.faqs.push(action.payload.data)
    })
    builder.addCase(addFaqAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(updateFaqAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(updateFaqAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      // state.faqs = action.payload.data
    })
    builder.addCase(updateFaqAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(deleteFaqAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(deleteFaqAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      const {id} = action.payload
      state.faqs = state.faqs.filter((data: any) => data?.id !== id)
    })
    builder.addCase(deleteFaqAction.rejected, (state, action) => {
      state.status = 'failed'
    })
  },
})

export const faqListSelector = (state: RootState) => state.faq.faqs
export const singleFaqSelector = (state: RootState) => state.faq.faq
export const faqStatusSelector = (state: RootState) => state.faq.status

export default faqSlice.reducer
