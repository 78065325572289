import client from '../client/client'

const getAllPaymentSummaryRequest = async (params: any) => {
  try {
    return await client.get('api/v1/admin/payment-summary', {
      params: params,
    })
  } catch (error) {
    return error
  }
}

export {getAllPaymentSummaryRequest}
