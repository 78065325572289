import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {useDispatch} from 'react-redux'
import {aggregateFilter, resetFilters} from '../../../reducers/exploreReducer'

const MediaFilters = ({filtersData}: any) => {
  // useEffect(() => {
  //   MenuComponent.reinitialization()
  // }, [])

  const dispatch = useDispatch()

  const [filterData, setFilterData] = useState<any>(filtersData?.mediaType)

  const handleResetFilter = () => {
    dispatch(resetFilters())
    setFilterData('')
  }

  const handleFilterSubmit = () => {
    dispatch(aggregateFilter({key: 'userType', value: filterData}))
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        // disabled={}
        type='button'
        className='btn btn-light-primary me-3 my-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5 text-center'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10 text-start'>
            <label className='form-label fs-6 fw-bold'>Media Type:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setFilterData(e.target.value)}
              value={filterData}
            >
              <option value=''>All</option>
              <option value='client'>Client</option>
              <option value='provider'>Provider</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
              onClick={handleResetFilter}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              // disabled={isLoading}
              type='button'
              onClick={handleFilterSubmit}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {MediaFilters}
