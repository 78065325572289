const DAY = [
  {name: 'Sunday', abb: 'SN', hasIt: false},
  {name: 'Monday', abb: 'M', hasIt: false},
  {name: 'Tuesday', abb: 'T', hasIt: false},
  {name: 'Wednesday', abb: 'W', hasIt: false},
  {name: 'Thursday', abb: 'TH', hasIt: false},
  {name: 'Friday', abb: 'F', hasIt: false},
  {name: 'Saturaday', abb: 'S', hasIt: false},
]

interface ThemeColors {
  primary: string
  white: string
  wheat: string
  dark: string
}

const COLOR: ThemeColors = {
  primary: '#04bac7',
  white: '#ffffff',
  wheat: '#dee2e6',
  dark: '#000',
}

const NA = 'N/a'

const MIN = 'minutes'

const appointmentStatus = [
  {
    value: JSON.stringify({
      status: 'approved,approveByProvider',
      isPaid: '',
    }),
    key: 'Active',
  },
  {
    value: JSON.stringify({
      status: 'pending,underTimeReserve',
      isPaid: '',
    }),
    key: 'Pending',
  },
  {
    value: JSON.stringify({
      status: 'expiredTimeReserve,cancelledByMember,dismissedByMember,declineByProvider',
      isPaid: '',
    }),
    key: 'Decline',
  },
  {
    value: JSON.stringify({
      status: 'completed',
      isPaid: '0,1',
    }),
    key: 'Past',
  },
  {
    value: JSON.stringify({
      status: 'completed',
      isPaid: '0,2',
    }),
    key: 'Unpaid',
  },
  {
    value: JSON.stringify({
      status: 'PaidByCash',
      isPaid: '0',
    }),
    key: 'Paid by cash',
  },
]

export {DAY, COLOR, NA, MIN, appointmentStatus}
