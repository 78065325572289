import {MDBDataTableV5} from 'mdbreact'
import TableLoading from '../../../_metronic/layout/components/Common/TableSkeletonLoading'
import {formatLocalTime} from '../../../util/helper'
import {DeleteIcon, LockIcon, ViewIcon} from '../../modules/common/TableIcons'
import {Link, useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useAppDispatch} from '../../store'
import {getAllUsersAction, usersListSelector, usersStatusSelector} from '../../reducers/userReducer'
import {useSelector} from 'react-redux'
import PasswordResetModal from '../profile/components/PasswordResetModal'
import {ToggleState} from '../Provider/modals/type'
import DeleteModal from './modals/DeleteModal'

type UserRow = {
  id: string | number
  name: string
  email: string
}

const UsersList = () => {
  const dispatch: any = useAppDispatch()

  const userList = useSelector(usersListSelector)
  const status = useSelector(usersStatusSelector)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const allowDelete: boolean = queryParams.get('super') === 'true'

  const [userData, setUserData] = useState<any>()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [toggle, setToggle] = useState<ToggleState[]>([{state: false, name: 'd-modal'}])

  const [initTable, setInitTable] = useState({
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Email',
        field: 'email',
        width: 270,
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows: [],
  })

  useEffect(() => {
    dispatch(
      getAllUsersAction({
        perPage: 50,
        sort: 'id|desc',
      })
    )
  }, [])

  function handleOpenModal(modal: string, itemId: string) {
    setToggle((prev) => {
      let modalState = prev.map((curState) => {
        if (curState.name === modal) {
          curState.state = true
          curState.id = itemId
        }
        return curState
      })
      return modalState
    })
  }

  useEffect(() => {
    if (userList) {
      const list: any = userList?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          email: item.email,
          action: [
            <LockIcon
              byClick={() => {
                handleOpen()
                setUserData({data: item, isAdmin: false})
              }}
              path=''
            />,
            allowDelete && (
              <DeleteIcon byClick={() => handleOpenModal('d-modal', item.id)} path='#' />
            ),
          ],
        }
      })
      setInitTable((prevTable) => {
        return {
          ...prevTable,
          rows: list,
        }
      })
    }
  }, [userList])

  return (
    <>
      <div className='row mb-5'>
        <div className='col-6'>
          <h2 className='py-3 mb-2'>Users</h2>
        </div>
        <div className='col-6 align-self-center text-end'>
          <Link to={`/users/create`}>
            <button type='button' className='btn btn-primary '>
              Create
            </button>
          </Link>
        </div>
      </div>
      <div
        style={{
          overflow: 'auto',
          whiteSpace: 'nowrap',
        }}
        className='card mb-5 mb-xl-10 p-5'
      >
        {status === 'loading' ? (
          <TableLoading />
        ) : (
          <MDBDataTableV5 hover order={['age', 'desc']} data={initTable} />
        )}
      </div>
      {toggle[0].state && <DeleteModal onHide={setToggle} toggle={toggle[0]} />}
      <PasswordResetModal open={open} handleClose={handleClose} userData={userData} />
    </>
  )
}

export {UsersList}
