import InfoWrapper from '../../modules/common/SectionInfo'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch} from '../../store'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {useEffect, useState} from 'react'
import {getSingleFaqAction, singleFaqSelector, updateFaqAction} from '../../reducers/faqReducer'
import {useSelector} from 'react-redux'

interface FormFields {
  question: string
  answer: string
}

const ViewFaq = () => {
  const {
    register: submit,
    handleSubmit: handleSubmitForm,
    control,
    formState: {errors, defaultValues},
    reset,
    getValues,
    watch,
    setValue,
  } = useForm<FormFields>({
    defaultValues: {
      question: '',
      answer: '',
    },
  })

  const [editor, setEditor] = useState<any>()
  const {id}: any = useParams()

  const navigate = useNavigate()
  const dispatch: any = useAppDispatch()

  const singleFaq = useSelector(singleFaqSelector)

  useEffect(() => {
    if (singleFaq) {
      reset({
        question: singleFaq?.question,
        answer: singleFaq?.answer,
      })
      // editor.setData(`${singleFaq?.answer}`)
    }
  }, [singleFaq])

  const onSubmit: SubmitHandler<FormFields> = (rData: any) => {
    const data = editor.getData()
    console.log('data', rData)

    const finalData = {
      ...rData,
      id: singleFaq?.id,
      answer: data,
    }

    dispatch(updateFaqAction(finalData))
  }

  useEffect(() => {
    dispatch(getSingleFaqAction(id))
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Update FAQ</h3>
        </div>
      </div>
      <form className='form' onSubmit={handleSubmitForm(onSubmit)}>
        <div className='card-body border-top px-9 pb-9 pt-0'>
          <InfoWrapper>
            <div className='row'>
              <div className='col-12'>
                <label className='col-form-label required fw-bold fs-6'>Question</label>
                <input
                  {...submit('question', {required: 'Name is required'})}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Question'
                />
                {errors.question?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.question?.message}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-12'>
                <label className='col-form-label required fw-bold fs-6'>Answer</label>
                <Controller
                  name='answer'
                  control={control}
                  rules={{required: 'Answer is required'}}
                  render={({field: {onChange, value}}: any) => (
                    <CKEditor
                      editor={ClassicEditor}
                      data={singleFaq?.answer}
                      config={{
                        toolbar: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          'link',
                          'bulletedList',
                          'numberedList',
                          'blockQuote',
                        ],
                        heading: {
                          options: [
                            {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                            {
                              model: 'heading1',
                              view: 'h1',
                              title: 'Heading 1',
                              class: 'ck-heading_heading1',
                            },
                            {
                              model: 'heading2',
                              view: 'h2',
                              title: 'Heading 2',
                              class: 'ck-heading_heading2',
                            },
                          ],
                        },
                      }}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log('Editor is ready to use!', editor)
                        setEditor(editor)
                      }}
                      onChange={(event) => {
                        onChange(event)
                      }}
                    />
                  )}
                />
                {errors.answer?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.answer?.message}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </InfoWrapper>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary'>
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default ViewFaq
