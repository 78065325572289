type chipProps = {
  text: boolean | string
  width?: string
}

export const Chip = ({text, width = '59px'}: chipProps) => {
  return (
    <span
      style={{width}}
      className={`d-flex align-items-center  justify-content-center badge text-center ${
        text ? 'badge-success' : 'badge-danger'
      }`}
    >
      {text ? 'Active' : 'InActive'}
    </span>
  )
}
