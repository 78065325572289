import ProfileOverview from './components/Overview'
import RoutingTabs from './components/RoutingTabs'

type Props = {
  tab?: string
  setTab?: any
}
const ProviderHeader = ({tab, setTab}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <ProfileOverview />
        <RoutingTabs tab={tab} setTab={setTab} />
      </div>
    </div>
  )
}

export {ProviderHeader}
