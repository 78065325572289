import InfoWrapper from '../../modules/common/SectionInfo'
import {SubmitHandler, useForm} from 'react-hook-form'
import {SHOW_SUCCESS} from '../../../util'
import {useNavigate} from 'react-router-dom'
import {useAppDispatch} from '../../store'
import {addTransactionAction} from '../../reducers/transactionsReducer'
import {useState} from 'react'

interface FormFields {
  type: string
  description: string
  isActive: 0 | 1
}

const CreateTransaction = () => {
  const {
    register: submit,
    handleSubmit: handleSubmitForm,
    formState: {errors},
    reset,
  } = useForm<FormFields>({
    defaultValues: {
      type: '',
      description: '',
      isActive: 0,
    },
  })

  const navigate = useNavigate()
  const dispatch: any = useAppDispatch()

  const [check, setCheck] = useState(0)

  const onSubmit: SubmitHandler<FormFields> = (rData: any) => {
    const finalData = {
      ...rData,
      isActive: check,
    }
    dispatch(addTransactionAction({data: finalData, navigate, SHOW_SUCCESS}))
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Create Transaction</h3>
        </div>
      </div>
      <form className='form' onSubmit={handleSubmitForm(onSubmit)}>
        <div className='card-body border-top px-9 pb-9 pt-0'>
          <InfoWrapper>
            <div className='row'>
              <div className=''>
                <label className='col-form-label required fw-bold fs-6'>Type</label>
                <select
                  {...submit('type', {required: 'Type is required'})}
                  className='form-select form-select-lg form-select-solid'
                >
                  <option value=''>Select Type</option>
                  <option value='r'>Refund</option>
                  <option value='t'>Tax</option>
                  <option value='d'>Discount</option>
                </select>
                {errors.type?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.type?.message}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className=''>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Description</span>
                </label>
                <textarea
                  {...submit('description', {required: 'Description is required'})}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                />
                {errors.description?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.description?.message}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex align-items-center'>
                <input
                  {...submit('isActive')}
                  type='checkbox'
                  className='form-check-input form-check-solid'
                  onChange={(e) => setCheck(e.target.checked ? 1 : 0)}
                />
                <label className='col-form-label fw-bold fs-6 ms-3'>
                  <span className='required'>isActive</span>
                </label>
                {/* {errors.isActive?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.isActive?.message}</span>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </InfoWrapper>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary'>
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default CreateTransaction
