import React from 'react'
import { MDBDataTableV5 } from 'mdbreact'
import { useParams } from 'react-router-dom'
import { makeDate } from '../../../../util'
import { checkAppointmentStatus, formatLocalTime } from '../../../../util/helper'
import { useProviderAppointment } from '../hooks/useProviderQuery'
import { UsersListFilter } from '../../../modules/apps/user-management/users-list/components/header/UsersListFilter'
import TableLoading from '../../../../_metronic/layout/components/Common/TableSkeletonLoading'

type ProviderAppointmentRow = {
  no: number
  clientNameFirst: string
  clientNameLast: string
  date: any
  startingTime: string
  endingTime: string
  duration: string
  fee: string
  paymentStatus: string
  totalPayment: string
  status: string
  tag: string
  id: string
}

const AppointementList = () => {
  const { Id } = useParams()

  const initTable = {
    columns: [
      {
        label: 'No',
        field: 'no',
        sort: 'disabled',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Client',
        field: 'clientName',
        width: 270,
      },
      {
        label: 'Date',
        field: 'date',
        width: 200,
      },
      {
        label: 'Time',
        field: 'time',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Status',
        field: 'status',
        width: 150,
      },
      {
        label: 'Duration',
        field: 'duration',
        width: 150,
      },
      {
        label: 'Fee',
        field: 'fee',
        width: 150,
      },
      {
        label: 'Payment',
        field: 'paymentStatus',
        width: 150,
      },
      {
        label: 'Total',
        field: 'totalPayment',
        width: 150,
      },
    ],
    rows: [],
  }

  const rowItem = (item: ProviderAppointmentRow) => {
    return {
      no: item.no,
      clientName: item.clientNameFirst + ' ' + item.clientNameLast,
      date: [<span className=''>{makeDate(item.date)}</span>],
      time: [
        <span className='badge badge-secondary'>{formatLocalTime(item.startingTime)}</span>,
        ' - ',
        <span className='badge badge-secondary'>{formatLocalTime(item.endingTime)}</span>,
      ],
      status: [
        <span
          className={`badge ${item.tag}`}
        >
          {item.status}
        </span>,
      ],
      duration: [item.duration, <span className='ms-1'>min</span>],
      fee: item.fee === null ? '0' : <span>${item.fee}</span>,
      paymentStatus: [
        <span className={`text-uppercase badge ${item.paymentStatus ? 'text-success' : 'text-danger'}`}>
          {item.paymentStatus ? 'paid' : 'unpaid'}
        </span>,
      ],
      totalPayment: '$' + item.totalPayment,
    }
  }

  const listSelector = (res: any) => {
    var rows: never[] = []

    res?.forEach((element: any, index: number) => {
      const { statusToShow, tag }: any = checkAppointmentStatus(
        element.status,
        element.isPaid,
        element.isReservationFee
      )
      const item: ProviderAppointmentRow = {
        no: index + 1,
        clientNameFirst: element.provider.firstName,
        clientNameLast: element.provider.lastName,
        date: element.appointmentDate,
        startingTime: element.startTime,
        endingTime: element.endTime,
        duration: element.totalDuration,
        fee: element.fee,
        paymentStatus: element.isPaid,
        totalPayment: element.totalPrice,
        status: statusToShow,
        id: element.id,
        tag: tag
      }
      rows.push(rowItem(item) as never)
    })

    var table = initTable
    table.rows = rows
    return table
  }

  const { isLoading, data } = useProviderAppointment(Id as string, listSelector)

  return (
    <>
      <div style={{ minHeight: '50vh' }} className={`card mb-5 mb-xl-10`}>
        <div className='row'>
          <div className='col-12 text-end'>
            <UsersListFilter />
          </div>
        </div>

        <div
          style={{
            overflow: 'auto',
            whiteSpace: 'nowrap',
          }}
          className='card mb-5 mb-xl-10 p-5'
        >
          {isLoading ? (
            <TableLoading />
          ) : (
            <MDBDataTableV5 hover order={['age', 'desc']} data={data} />
          )}
        </div>
      </div>
    </>
  )
}

export default AppointementList
