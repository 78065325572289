import React from 'react'
import { UsersListFilter } from '../../../modules/apps/user-management/users-list/components/header/UsersListFilter';

const ProvidersPayments = () => {
  const [datatable] = React.useState({
    columns: [
      {
        label: 'No',
        field: 'no',
        sort: 'disabled',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Client Name',
        field: 'clientName',
        width: 270,
      },
      {
        label: 'Date',
        field: 'date',
        width: 200,
      },
      {
        label: 'Time',
        field: 'time',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Status',
        field: 'status',
        width: 150,
      },
      {
        label: 'Duration',
        field: 'duration',
        width: 150,
      },
      {
        label: 'Fee',
        field: 'fee',
        width: 150,
      },
      {
        label: 'Payment',
        field: 'paymentStatus',
        width: 150,
      },
      {
        label: 'Total',
        field: 'totalPayment',
        width: 150,
      },
    ],
    rows: [
      {
        no: 10,
        clientName: ['Max'],
        date: [<span className='badge badge-secondary'>3 July 2022</span>],
        time: [<span className='badge badge-secondary'>9:00 AM</span>, ' - ', <span className='badge badge-secondary'>10:00 PM</span>],
        status: [<span className='badge badge-success'>Active</span>],
        duration: [<span className='badge badge-secondary'>2 hours</span>],
        fee: '$400',
        paymentStatus: [<span className='badge badge-success'>Paid</span>],
        totalPayment: "$700",
      },
      {
        no: 10,
        clientName: ['Max'],
        date: [<span className='badge badge-secondary'>3 July 2022</span>],
        time: [<span className='badge badge-secondary'>9:00 AM</span>, ' - ', <span className='badge badge-secondary'>10:00 PM</span>],
        status: [<span className='badge badge-danger'>Unactive</span>],
        duration: [<span className='badge badge-secondary'>2 hours</span>],
        fee: '$400',
        paymentStatus: [<span className='badge badge-danger'>Unpaid</span>],
        totalPayment: "$700",
      },

    ],
  });


  return (
    <>
      <div style={{ minHeight: '50vh' }} className='card mb-5 mb-xl-10'>
        <div className="row">
          <div className="col-12 text-end">
            <UsersListFilter />
          </div>
        </div>

        <div className='card mb-5 mb-xl-10 p-5'>
          {/* <MDBDataTableV5 hover order={['age', 'desc']} data={datatable} /> */}
          <img
            src={'/media/illustrations/sketchy-1/5.png'}
            alt='provider avatar'
            height={400}
            style={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    </>
  )
}

export default ProvidersPayments;
