import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box'
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import {MDBDataTableV5} from 'mdbreact'
import {DeleteIcon, LockIcon, ViewIcon} from '../../modules/common/TableIcons'
import TableLoading from '../../../_metronic/layout/components/Common/TableSkeletonLoading'
import PasswordResetModal from './components/PasswordResetModal'
import moment from 'moment'
import {Chip} from '../../modules/common/Chip'
import Verified from '../../modules/common/Verified'
import {toast} from 'react-toastify'
import {deleteMembers} from '../../../api/endpoints/member'
import {useMemberList, useMemberStatusUpdate} from './hooks/useMemberQuery'
import {makeDate} from '../../../util'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {
  getConfigurationByKeyAction,
  singleConfigurationSelector,
} from '../../reducers/configurationReducer'
import {useAppDispatch} from '../../store'
import {updateMemberStatusAction} from '../../reducers/memberReducer'
import {Switch, styled, switchClasses} from '@mui/material'
import {SwitchTextTrack} from '../../../config'
import MUITable from '../../modules/common/MUITable'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#1e1e2d',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

type MemberRow = {
  profilePic: string
  firstName: string
  lastName: string
  phone: string
  lastLogin: any
  status: string
  id: string
  timezone: string
  isVerified: boolean
}

export default function ListMember() {
  const [deleteItem, setDeleteItem] = React.useState('')
  const [userData, setUserData] = React.useState<any>()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [open2, setOpen2] = useState(false)
  const handleOpen2 = () => setOpen2(true)
  const handleClose2 = () => setOpen2(false)
  const dispatch: any = useAppDispatch()
  const [id, setId] = useState('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [search, setSearch] = useState('')

  useEffect(() => {
    dispatch(getConfigurationByKeyAction('provider_approval_flow_bypass'))
    return () => {}
  }, [])

  const configuration = useSelector(singleConfigurationSelector)

  const initTable = {
    columns: [
      {id: 'profilePic', label: '', numeric: false, disablePadding: true},
      {id: 'firstName', label: 'First name', numeric: false, disablePadding: true},
      {id: 'lastName', label: 'Last name', numeric: false, disablePadding: true},
      {
        id: 'phone',
        label: 'Full number',
        // align: 'right',
        numeric: true,
        disablePadding: true,
      },
      {
        label: 'Last login at',
        id: 'lastLoginAt',
        numeric: false,
        disablePadding: true,
      },
      {
        id: 'timezone',
        label: 'Timezone',
        // align: 'right',
        numeric: false,
        disablePadding: true,
      },
      {
        id: 'status',
        label: 'Status',
        // align: 'right',
        numeric: false,
        disablePadding: true,
      },
      {
        id: 'action',
        label: 'Action',
        // align: 'right',
        numeric: false,
        disablePadding: true,
      },
    ],
    rows: [],
  }

  const [deleteSuccess, setDeleteSuccess] = useState(false) // state to track delete success
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const allowDelete = queryParams.get('super') === 'true'

  const {isLoading: statusLoading, mutate} = useMemberStatusUpdate()

  // const handleStatusUpdate = (data: any) => {
  //   dispatch(updateMemberStatusAction(data))
  //   refetch()
  // }

  const handleDelete = async (id: string) => {
    try {
      await deleteMembers(id) // call deleteProvider with the id
      setDeleteSuccess(!deleteSuccess) // update delete success state
      toast.success('Member successfully deleted', {
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    } catch (error) {
      console.error('Failed to delete provider:', error)
      toast.error('Something went wrong', {
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }

  const rowItem = (item: MemberRow, data: any) => {
    return {
      profilePic: (
        <img
          src={
            item?.profilePic ? item?.profilePic : '/media/icons/duotune/communication/com006.svg'
          }
          alt=''
          className='rounded-circle h-40px w-40px'
        />
      ),
      firstName: [<Verified isVerified={item.isVerified} />, item.firstName],
      lastName: item.lastName,
      phone: item.phone,
      lastLoginAt: [
        <span className='badge badge-secondary'>
          {item.lastLogin ? makeDate(item.lastLogin) : 'N/a'}
        </span>,
      ],
      timezone: item.timezone,
      status: [
        statusLoading || loading ? (
          id === item?.id ? (
            <div className='ms-1'>
              <span className='spinner-border align-middle'></span>
            </div>
          ) : (
            <SwitchTextTrack
              checked={item?.status === 'approved' ? true : false} // Handle checkbox checked state
            />
          )
        ) : (
          <SwitchTextTrack
            onChange={(e) => {
              if (configuration?.value?.status === 1) {
                mutate({
                  clientId: item?.id,
                  status: item?.status === 'approved' ? 'pending' : 'approved',
                })
                setId(item?.id)
              }
            }}
            disabled={configuration?.value?.status !== 1 ? true : false}
            checked={item?.status === 'approved' ? true : false} // Handle checkbox checked state
          />
        ),
      ],
      action: [
        <ViewIcon
          path={item.status ? `/members/${item.id}` : ``}
          data={data}
          active={item.status ? true : false}
        />,
        // <EditIcon path='#' />,
        <LockIcon
          byClick={() => {
            handleOpen()
            setUserData(item)
          }}
          path=''
        />,
        allowDelete ? (
          <DeleteIcon
            byClick={() => {
              handleOpen2()
              setDeleteItem(item.id)
            }}
            path='#'
          />
        ) : (
          <></>
        ),
      ],
    }
  }

  const listSelector = (res: any) => {
    const data = res

    var rows: never[] = []

    let pagination
    // console.log(res)

    pagination = {
      total: res.total,
      lastPage: res.lastPage,
    }

    data?.clients.forEach((element: any) => {
      const item: MemberRow = {
        profilePic: element?.profileImage,
        firstName: element?.firstName,
        lastName: element?.lastName,
        phone: element?.mobileNo,
        lastLogin: element?.lastLogin,
        status: element?.status,
        timezone: element?.timezone,
        isVerified: element?.isMobileVerified,
        id: element?.id,
      }
      rows.push(rowItem(item, element) as never)
    })

    var table = initTable
    table.rows = rows
    return {table, pagination}
  }

  const {isLoading, data, isFetching} = useMemberList({
    func: listSelector,
    params: {
      page,
      perPage,
      q: search,
    },
  })

  useEffect(() => {
    setLoading(isFetching)
  }, [isFetching])

  // console.log('lod', statusLoading)

  return (
    <>
      <div className='row mb-5'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <h2 className='py-3 mb-2'>Members</h2>
          <div>
            <input
              type='search'
              onChange={(e) => {
                if (e.target.value.length >= 3) {
                  setSearch(e.target.value)
                }
                if (e.target.value === '') {
                  setSearch(e.target.value)
                }
              }}
              placeholder='Search'
              className='form-control form-control-sm'
            />
          </div>
        </div>
        <div className='col-6 align-self-center text-end'>
          {/* <button type="button" className="btn btn-primary">
              Create
            </button> */}
        </div>
      </div>
      <div
        style={{
          overflow: 'auto',
          whiteSpace: 'nowrap',
        }}
        className='card mb-5 mb-xl-10 p-5'
      >
        {isLoading ? (
          <TableLoading />
        ) : (
          <MUITable
            data={data}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        )}
      </div>
      <PasswordResetModal open={open} handleClose={handleClose} userData={userData} />
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            className='text-center text-white'
            id='modal-modal-title'
            variant='h6'
            component='h2'
          >
            Are you sure to delete it ?
          </Typography>
          <div className='d-flex justify-content-between mt-4 mx-4'>
            <div>
              <button
                onClick={() => {
                  handleClose2()
                  handleDelete(deleteItem)
                }}
                type='button'
                className='btn btn-success'
                aria-label='submit data'
              >
                Yes
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  handleClose2()
                }}
                type='button'
                className='btn btn-danger'
                aria-label='submit data'
              >
                No
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}
