import client from '../client/client'

const getAllFaqRequest = async (params: any) => {
  try {
    return await client.get('api/v1/admin/faq', {
      params: params,
    })
  } catch (error) {
    return error
  }
}

const getSingleFaqRequest = async (id: number | string) => {
  try {
    return await client.get(`api/v1/admin/faq/${id}`)
  } catch (error) {
    return error
  }
}

const addFaqRequest = async (data: any) => {
  try {
    return await client.post('api/v1/admin/faq', data)
  } catch (error) {
    return error
  }
}

const updateFaqRequest = async (data: any) => {
  try {
    return await client.put(`api/v1/admin/faq/${data?.id}`, data)
  } catch (error) {
    return error
  }
}

const deleteFaqRequest = async (id: string | number) => {
  try {
    return await client.delete(`api/v1/admin/faq/${id}`)
  } catch (error) {
    return error
  }
}

export {addFaqRequest, getAllFaqRequest, getSingleFaqRequest, deleteFaqRequest, updateFaqRequest}
