/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import MultiSchedule from './MultiSchedule'

type Props = {
    data: any
}

const MultitoMulti: FC<Props> = ({ data }) => {
    return (
        <div style={{ minHeight: '50vh' }} className={`card ${data?.length === 0 && "d-flex justify-content-center"}`}>
            {data.length !== 0 ?
                data?.map((service: any) => (
                    <MultiSchedule data={service} />
                ))
                :
                <div className='text-center mt-4 mb-5'>
                    <h3 className='text-muted'>No matching records found :(</h3>
                </div>
            }
        </div>
    )
}

export { MultitoMulti }
